import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import pdf from "@/static/icons/pdf.svg";
import excel from "@/static/icons/excel.svg";
import plus from "@/static/icons/plus.svg";
import { Typography } from "@material-tailwind/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { AppService } from "@/service/AllApiData.service";
import { Alert } from "@/alert/Alert";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { useaddUserStyle } from "@/static/stylesheets/molecules/addUser";
import { getWholeSellerId } from "@/helper";

interface HeaderSectionProps {
  selectedUser: any;
  setSelectedUser: any;
  open: boolean;
  setOpen: any;
  reloadUsers: any;
}

const HeaderSection = ({
  selectedUser,
  setSelectedUser,
  open,
  setOpen,
  reloadUsers,
}: HeaderSectionProps) => {
  const navigate = useNavigate();
  // const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [state, setState] = useState<any>([]);
  const [agents, setAgents] = useState<any>([]);
  const [bazaars, setBazaars] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [managers, setManagers] = useState<any>([]);
  const [managerList, setManagerList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const classes = useaddUserStyle();

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    roles: "",
    branch: "",
    bazaar: "",
    zonal_manager: "",
    email: "",
    city: [],
    district: [],
    state: [],
  });

  useEffect(() => {
    if (selectedUser) {
      console.log("logger123...", selectedUser);
      setFormData({
        username: selectedUser?.username,
        first_name: selectedUser?.first_name,
        roles: selectedUser?.roles?.id,
        bazaar: selectedUser?.bazzar?.id,
        zonal_manager: selectedUser?.zonal_manager?.id,
        email: selectedUser?.email,
        city: selectedUser?.city_many || [],
        district: selectedUser?.district_many || [],
        state: selectedUser?.state_many || [],
        branch: selectedUser?.branch,
      });
      if (selectedUser?.bazzar?.id) {
        getStates(selectedUser?.bazzar?.id);
      }
      if (selectedUser?.district_many?.length) {
        getCities(selectedUser?.district_many.map((item: any) => item.id));
      }
      if (selectedUser?.state_many?.length) {
        getDistricts(selectedUser?.state_many?.map((item: any) => item.id));
      }
    }
  }, [selectedUser]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getBazaars();
    getBranches();
    getRoles();
    getManagers();
  }, []);

  const getBazaars = async () => {
    const responseJson = await AppService.getAllBazaar();
    setBazaars(responseJson.data.results);
  };
  const getBranches = async () => {
    const responseJson = await AppService.getAllBranches(getWholeSellerId());
    setBranches(responseJson.data.results);
  };
  const getRoles = async () => {
    const responseJson = await AppService.getRoles();
    setRoles(responseJson.data.roles);
  };
  const getStates = async (bazaar: any) => {
    setStateList([]);
    setDistrictList([]);
    setCityList([]);
    if (bazaar) {
      let params = {
        ids: bazaar,
      };
      const responseJson = await AppService.getAllStateByBazaar(params);
      let states = responseJson.data.results.flatMap((item: any) =>
        item.bazaar_state_data.map((state: any) => state)
      );
      setStateList(states);
    }
  };

  const getDistricts = async (states: any) => {
    setDistrictList([]);
    setCityList([]);
    let params = {};
    if (states.length) {
      params = {
        ids: states.join(","),
      };
      const responseJson = await AppService.getAllDistricByState(params);
      let districts = responseJson.data.results.flatMap((item: any) =>
        item.district.map((district: any) => district)
      );
      console.log("logger123...", districts);
      setDistrictList(districts);
    }
  };

  const getCities = async (districts: any) => {
    setCityList([]);
    let params = {};
    if (districts.length) {
      params = {
        ids: districts.join(","),
      };
      const responseJson = await AppService.getAllCityByDis(params);
      let cities = responseJson.data.results.flatMap((item: any) =>
        item.city.map((city: any) => city)
      );
      setCityList(cities);
    }
  };

  const getManagers = async () => {
    const response = await AppService.getUsers();
    setManagers(response.data);
  };

  function areElementsInArray(sourceArray: any = [], targetArray: any = []) {
    return sourceArray.every((element: any) => targetArray.includes(element));
  }

  const filterManagers = (values: any) => {
    let filteredManagers = [];
    if (values?.roles) {
      filteredManagers = managers.filter(
        (item: any) => item?.roles?.id > values?.roles
      );
      console.log("filtered by bazaar ", filteredManagers, values);
      if (values.bazaar) {
        filteredManagers = filteredManagers.filter(
          (item: any) => item?.bazzar?.id === values?.bazaar
        );

        if (values?.state_many?.length) {
          filteredManagers = filteredManagers.filter((item: any) =>
            areElementsInArray(
              item?.state_many?.map((ele: any) => ele.id),
              values?.state?.map((item: any) => item.id)
            )
          );

          if (values?.district_many?.length) {
            filteredManagers = filteredManagers.filter((item: any) =>
              areElementsInArray(
                item?.district_many?.map((ele: any) => ele.id),
                values?.district?.map((item: any) => item.id)
              )
            );

            if (values?.city_many?.length) {
              filteredManagers = filteredManagers.filter((item: any) =>
                areElementsInArray(
                  item?.city_many?.map((ele: any) => ele.id),
                  values?.city?.map((item: any) => item.id)
                )
              );
            }
          }
        }
      }
    }

    console.log("filtered manager ", filteredManagers, values);
    setManagerList(filteredManagers);
  };

  useEffect(() => {
    if (formData.username) {
      filterManagers(formData);
    }
  }, [formData]);

  const createUser = async () => {
    await AppService.createUser(formData);
    setOpen(false);
    Alert("User created Successfully");
  };

  const handleSubmit = async (values: any) => {
    console.log("regergerg", values);
    if (selectedUser?.id) {
      let body = {
        ...values,
        wholeseller_id: getWholeSellerId(),
        // city_many: values?.city?.map((item: any) => item.id),
        // district_many: values?.district?.map((item: any) => item.id),
        // state_many: values?.state?.map((item: any) => item.id),
        // password: "admin@123"
      };
      // delete body.city;
      // delete body.district;
      // delete body.state;
      const response = await AppService.updateUser(selectedUser?.id, body);
      setSelectedUser();
      reloadUsers();
      setOpen(false);
      Alert("User Updated Successfully");
    } else {
      let body = {
        ...values,
        wholeseller: getWholeSellerId(),
        // city_many: values?.city?.map((item: any) => item.id),
        // district_many: values?.district?.map((item: any) => item.id),
        // state_many: values?.state?.map((item: any) => item.id),
        // password: "admin@123"
      };
      // delete body.city;
      // delete body.district;
      // delete body.state;
      let params = {
        wholesaler: getWholeSellerId(),
      };
      await AppService.createUser(body, params);
      setOpen(false);
      setSelectedUser();
      Alert("User created Successfully");
    }
  };

  // useEffect(()=>{
  //   filterManagers(formData)
  // },[formData])

  const validationSchema = yup.object({
    username: yup.string().required("Phone Number is required"),
    first_name: yup.string().required("Full Name is required"),
    // roles: yup.string().required("Roles is required"),
    branch: yup.number().required("Branch is required"),
    // zonal_manager: yup.number(),
    email: yup.string().email("Email is invalid").required("Email is required"),
    // city: yup.array().min(1, "City is required").required("City is required"),
    // district: yup.array().min(1, "District is required").required("District is required"),
    // state: yup.array().min(1, "State is required").required("State is required"),
  });

  const handleStateChange = (
    list: any,
    e: any,
    setState: any,
    city = false,
    type: any = ""
  ) => {
    if (e.length > 1) {
      if (type?.all === true) {
        if (city) {
          let body = { target: { name: "city", value: [...list, ...e] } };
          setState(body);
        } else {
          let body = { target: { name: "district", value: [...list, ...e] } };
          setState(body);
          // setDistricts1([...list,...e])
        }
      } else {
        if (city) {
          let body = {
            target: {
              name: "city",
              value: [...list.filter((item: any) => !e.includes(item))],
            },
          };
          setState(body);
        } else {
          let body = {
            target: {
              name: "district",
              value: list.filter((item: any) => !e.includes(item)),
            },
          };
          setState(body);
          // setDistricts1(list.filter((item:any)=>!e.includes(item)))
        }
      }
    } else {
      if (list.includes(e[0])) {
        if (city) {
          let body = {
            target: {
              name: "city",
              value: [...list.filter((item: any) => item !== e[0])],
            },
          };
          setState(body);
        } else {
          let body = {
            target: {
              name: "district",
              value: [...list.filter((item: any) => item !== e[0])],
            },
          };
          setState(body);
          // setDistricts1([...list.filter((item: any) => item !== e[0])])
        }
      } else {
        if (city) {
          let body = { target: { name: "city", value: [...list, e[0]] } };
          setState(body);
        } else {
          let body = { target: { name: "district", value: [...list, e[0]] } };
          setState(body);
          // setDistricts1([...list, e[0]])
        }
      }
    }
  };

  return (
    <Box className="flex items-center justify-between">
      <Box className="flex items-center gap-6">
        <a
          className="text-sm font-semibold text-[#2E2C34] pb-3 px-3 border-b-[2px] border-[#5542F6] inline-block cursor-pointer"
          onClick={() => navigate("/users")}
        >
          Users
        </a>
        <a
          className="text-sm font-normal text-[#84818A] pb-3 px-3 inline-block border-b-[2px] border-transparent cursor-pointer"
          onClick={() => navigate("/rolespermissions")}
        >
          Roles and Permissions
        </a>
      </Box>
      <Box className="flex items-center gap-[10px]">
        <a className="pdf-link">
          {" "}
          <img src={pdf} alt="PDF" /> Export to PDF
        </a>
        <a className="excel-link">
          {" "}
          <img src={excel} alt="pdf" /> Export to Excel
        </a>

        <div className="inline-block">
          <a className="add-rules cursor-pointer" onClick={handleClickOpen}>
            <img src={plus} alt="Plus" /> Add New User
          </a>
          <Formik
            validationSchema={validationSchema}
            initialValues={formData}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <Dialog
                PaperProps={{
                  style: { borderRadius: 20, boxShadow: "none", padding: 0 },
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="rolePOPUP"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  className="!text-[#2E2C34] !px-[45px] !text-[24px] !font-[Manrope] !font-[600]"
                >
                  {"Add New User"}
                </DialogTitle>
                <DialogContent className="border-b border-[#EBEAED] !px-[45px]">
                  <Typography className="form-name">General Details</Typography>

                  <div>
                    <div className="flex items-center gap-[32px] mt-[29px]">
                      <div>
                        <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Full Name<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="border-b block border-[#EBEAED] w-[244px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="first_name" />
                        </div>
                      </div>
                      <div className={classes.root}>
                        <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Phone<span className="text-red-600">*</span>
                        </label>

                        <PhoneInput
                          country={"in"}
                          onChange={(phone: any) =>
                            setFieldValue("username", phone)
                          }
                          value={values.username}
                        />
                        {/* <input
                              type="text"
                              name="username"
                              className="border-b block border-[#EBEAED] w-[244px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                              onChange={handleChange}
                              value={values.username}
                            /> */}
                        <div className="text-red-600">
                          <ErrorMessage name="username" />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-end gap-[32px] mt-[29px]">
                      <div>
                        <label className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Email ID<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="border-b block border-[#EBEAED] w-[244px] text-[14px] font-[Manrope] font-[500] text-[#2E2C34] outline-none"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <div className="text-red-600">
                          <ErrorMessage name="email" />
                        </div>
                      </div>

                      <div className="w-full">
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Branch<span className="text-red-600">*</span>
                        </p>

                        <Select
                          fullWidth
                          variant="standard"
                          name="branch"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.branch}
                        >
                          {branches.map((item: any) => (
                            <MenuItem value={item.id}>
                              {item.branch_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <div className="text-red-600">
                          <ErrorMessage name="branch" />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-[32px] mt-[29px]"></div>

                    <Box className="flex justify-between items-center mt-10">
                      <Typography className="text-[#4E2FA9] text-[16px] font-[Manrope] font-semibold">
                        Role and Regions
                      </Typography>
                      <p
                        onClick={() => navigate("/addroles")}
                        className="text-[#FF6652] text-[16px] font-[Manrope] font-semibold underline cursor-pointer"
                      >
                        Add New Role
                      </p>
                    </Box>

                    <div className="flex items-center gap-[32px] mt-[29px]">
                      <div className="w-full">
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Role<span className="text-red-600">*</span>
                        </p>

                        <Select
                          fullWidth
                          variant="standard"
                          name="roles"
                          onChange={(e) => {
                            handleChange(e);
                            filterManagers({
                              ...values,
                              roles: e.target.value,
                            });
                            setFieldValue("zonal_manager", "");
                          }}
                          value={values.roles}
                        >
                          {roles.map((role: any) => (
                            <MenuItem value={role.id}>{role.name}</MenuItem>
                          ))}
                        </Select>

                        <div className="text-red-600">
                          <ErrorMessage name="roles" />
                        </div>
                      </div>

                      <div className="w-full">
                        <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">
                          Select Manager
                        </p>

                        <Select
                          fullWidth
                          variant="standard"
                          name="zonal_manager"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.zonal_manager}
                        >
                          {managerList.map((item: any) => (
                            <MenuItem value={item?.id}>
                              {item?.first_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <div className="text-red-600">
                          <ErrorMessage name="zonal_manager" />
                        </div>
                      </div>

                      {/* <div className="w-full">
                            <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">Bazaar</p>
  
                            <Select
                              fullWidth
                              variant="standard"
                              name="bazaar"
                              onChange={(e) => {
                                handleChange(e);
                                getStates(e.target.value);
                                setFieldValue("state", []);
                                setFieldValue("district", []);
                                setFieldValue("city", []);
                                filterManagers({
                                  ...values,
                                  bazaar: e.target.value
                                })
                                setFieldValue("zonal_manager","")
                              }}
                              value={values.bazaar}
                            >
                              {bazaars.map((item: any) => (
                                <MenuItem
                                  value={item.id}
                                >
                                  {item.bazaar_name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div className="text-red-600">
                              <ErrorMessage name="bazaar" />
                            </div>
                          </div> */}
                    </div>

                    {/* <div className="flex items-center gap-[32px] mt-[29px]">
                          <div className="w-full">
                            <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">State</p>
  
                            <Autocomplete
                              disablePortal
                              id="states"
                              options={stateList}
                              getOptionLabel={(option: any) => option.state}
                              fullWidth
                              multiple
                              onChange={(e: any, value) => {
                                handleChange({ target: { name: "state", value: value } })
                                getDistricts(value.map((item: any) => item.id))
                                setFieldValue("district", []);
                                setFieldValue("city", []);
                                filterManagers({
                                  ...values,
                                  state: e.target.value
                                })
                                setFieldValue("zonal_manager","")
                              }}
                              value={values.state}
                              renderInput={(params) => <TextField {...params} name="state" variant="standard" />}
                            />
  
                            <div className="text-red-600">
                              <ErrorMessage name="state" />
                            </div>
                          </div>
  
                          <div className="w-full">
                            <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">District</p>
  
                            <Autocomplete
                              disablePortal
                              id="states"
                              options={districtList}
                              getOptionLabel={(option: any) => option.district}
                              fullWidth
                              multiple
                              onChange={(e: any, value) => {
                                console.log("logger123...", e, value)
                                handleChange({ target: { name: "district", value: value } })
                                getCities(value.map((item: any) => item.id))
                                setFieldValue("city", []);
                                filterManagers({
                                  ...values,
                                  district: e.target.value
                                })
                                setFieldValue("zonal_manager","")
                              }}
                              value={values.district}
                              renderInput={(params) => <TextField {...params} name="district" variant="standard" />}
                            />
  
                            <div className="text-red-600">
                              <ErrorMessage name="district" />
                            </div>
                          </div>
                        </div>
  
                        <div className="flex items-center gap-[32px] mt-[29px]">
                          <div className="w-full">
                            <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">City</p>
  
                            <Autocomplete
                              disablePortal
                              id="states"
                              options={cityList}
                              getOptionLabel={(option: any) => option.city}
                              fullWidth
                              multiple
                              onChange={(e: any, value) => {
                                console.log("logger123...", e, value)
                                handleChange({ target: { name: "city", value: value } })
                                filterManagers({
                                  ...values,
                                  city: e.target.value
                                })
                                setFieldValue("zonal_manager","")
                                // getCities(value.map((item: any) => item.id))
                              }}
                              value={values.city}
                              renderInput={(params) => <TextField {...params} name="city" variant="standard" />}
                            />
  
                            <div className="text-red-600">
                              <ErrorMessage name="city" />
                            </div>
                          </div>
  
                          <div className="w-full">
                            <p className="text-[14px] font-[Manrope] font-[500] text-[#84818A]">Select Manager</p>
  
                            <Select
                              fullWidth
                              variant="standard"
                              name="zonal_manager"
                              onChange={(e) => {
                                handleChange(e)
                              }}
                              value={values.zonal_manager}
                            >
                              {managerList.map((item: any) => (
                                <MenuItem
                                  value={item?.id}
                                >
                                  {item?.first_name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div className="text-red-600">
                              <ErrorMessage name="zonal_manager" />
                            </div>
                          </div>
  
                        </div> */}
                  </div>
                </DialogContent>
                <DialogActions className="!my-[20px] !mx-[20px] items-center gap-[12px] flex !justify-start">
                  <ActionButton
                    onClick={handleClose}
                    variant="default"
                    title="Cancel"
                  />
                  <ActionButton
                    onClick={handleSubmit}
                    variant="primary"
                    title="Save Changes"
                  />
                </DialogActions>
              </Dialog>
            )}
          </Formik>
        </div>
      </Box>
    </Box>
  );
};

export { HeaderSection };
