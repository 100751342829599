import React, { useEffect, useState } from "react";
import { AuthenticationLayout } from "@/components/layouts";
import { LoginForm, OtpForm, OtpSuccess } from "@/components/molecules/Login";
import { number, string } from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserNumberVerify from "@/Store/Sclicese/WholesellerNumberVerify";
import { AppDispatch, useDispatch, useSelector } from "@/Store";
import { OtpVerification } from "@/Store/Sclicese/OtpVerificationSlice";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [isOtpVerification, setIsOtpVerification] = useState<boolean>(false);
  const [loginNum, setloginNum] = useState(false);
  const [otpvar, setotpvar] = useState(false);
  const [userNumber, setUserNumber] = useState<string>("");
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Numberverifi = useSelector(
    (state) => state.WholesellerNumber.data.details
  );
  const Otpverifi = useSelector((state) => state.OtpVerification.data);
  const otpError = useSelector((state) => state.OtpVerification.error);
  let array: string[];
  array = [];
  const inputvalues = (data: any) => {
    if (data.length == 10) {
      setUserNumber(data);
      setloginNum(true);
    } else {
      setloginNum(false);
    }
  };
  const otpnumber = (obj: any) => {
    array = [];
    if (
      obj.input1 !== "" &&
      obj.input2 !== "" &&
      obj.input3 !== "" &&
      obj.input4 !== "" &&
      obj.input5 !== "" &&
      obj.input6 !== ""
    ) {
      for (let key in obj) {
        array.push(obj[key]);
      }
      return array.join(",").replaceAll(",", "");
    }
    return "";
  };
  const handleCheckLogin = () => {
    const Formdata = new FormData();
    if (!!loginNum) {
      // setIsOtpVerification(true)
      Formdata.append("wholeseller_number", "+91" + userNumber);
      dispatch(UserNumberVerify(Formdata));
      // toast.success('success!')
    } else {
      setIsOtpVerification(false);
      toast.error("wrong number!");
    }
  };
  const handleCheckOTP = (obj: any) => {
    const otpNumber = otpnumber(obj);
    console.log(otpNumber);
    if (otpNumber.length == 6) {
      const Formdata = new FormData();
      Formdata.append("wholeseller_number", `+91${userNumber}`);
      Formdata.append("wholeseller_otp", `${otpNumber}`);
      dispatch(OtpVerification(Formdata));

      setOtpSuccess(otpvar ? true : false);
    } else {
      setOtpSuccess(otpvar ? true : false);
      toast.error("wrong otp!");
    }
  };

  useEffect(() => {
    if (Numberverifi == "Wholeseller OTP sent to registered mobile Number") {
      setIsOtpVerification(true);
    }
  }, [Numberverifi]);

  useEffect(() => {
    console.log(Otpverifi);
    if (Otpverifi?.access_token) {
      console.log("somethingOtpverifi", Otpverifi);
      setOtpSuccess(false);
      setIsOtpVerification(false);
      navigate("/dashboard");
    }
  }, [Otpverifi]);

  useEffect(() => {
    if (otpError) {
      toast.error("wrong otp");
    }
  }, [otpError]);
  return (
    <div>
      <AuthenticationLayout>
        {!isOtpVerification ? (
          <LoginForm
            onSuccess={handleCheckLogin}
            inputvalues={inputvalues}
            Verification={isOtpVerification}
          />
        ) : (
          <>
            {!otpSuccess ? (
              <OtpForm
                onGoBack={() => setIsOtpVerification(false)}
                onSuccess={handleCheckOTP}
                otp={otpnumber}
                userNumber={userNumber}
              />
            ) : (
              <OtpSuccess />
            )}
          </>
        )}
      </AuthenticationLayout>
    </div>
  );
};
export default Login;
