import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "@/components/layouts";
import { useAddnewProductStyles } from "@/static/stylesheets/screens";
import UploadImg from "@/static/svg/ic_bulkupload.svg";
import { useDispatch, useSelector } from "@/Store";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { AnyAction } from "@reduxjs/toolkit";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { FilteredProductsWholeSeller, clearFilteredProducts } from "@/Store/Sclicese/filteredProductsSlice";
import { Dialog } from "@mui/material";
import Placeholder from "@/static/images/Screenshot 2022-07-24 at 4.00.png";
import { SearchField } from "@/components/atoms/SearchField";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import barIcon from "@/static/svg/ic_barcode.svg";
import ProductDetailModal from "../Products/ProductsDetail";


type props = {
    addModalOpen: boolean;
    setAddModalOpen: any;
    selectedItems: any;
    setSelectedItems: any;
    branch: any;
    // retailerId: any;
    // plan: any;
    // branchId: any;
    // orderType: any;
}
const ProductSelect = ({ addModalOpen, setAddModalOpen, selectedItems, setSelectedItems, branch }: props) => {
    const classes = useAddnewProductStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [masterType, setMasterType] = useState("Regional Wholeseller");
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedType, setselectedType] = useState<any>({});
    const ProductBazaar = useSelector(state => state?.ProductBazaarList?.data?.results)
    const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)
    const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
    const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
    const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results)
    const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "")
    const [search, setSearch] = useState("");
    const [productdata, setProductdata] = useState<any>();
    const [showDetails, setShowDetails] = useState<any>(false);


    const [selectedProduct, setSelectedProduct] = useState<any>(selectedItems || [])


    useEffect(() => {
        if (selectedItems?.length) {
            setSelectedProduct(selectedItems || [])
        }
    }, [selectedItems])

    const hadleSubmitProductSelection = () => {
        const tmpArr = selectedProduct || []
        console.log("logger123.... =======> ", selectedProduct);
        setSelectedItems([...selectedProduct.map((item: any) => (
            {
                ...item,
                // quantity: item.product_min_quantity || 1
                quantity: 1
                // product_selling_price: item.branch_price.find((ele: any) => ele.branch === branch.id).price
            }
        ))])
        setSelectedProduct([]);
        setAddModalOpen(false)
    }

    const handleProductSelection = (product: any) => {
        const isExist = selectedProduct?.filter((itm: any) => itm.id === product.id)[0]
        let tempErr = selectedProduct
        if (isExist) {
            tempErr = selectedProduct?.filter((itm: any) => itm.id !== product.id)
        } else {
            let amount = product.product_selling_price;
            // if (plan === "CASH") {
            //     if (product.retailer_base_plan.cash_discount_type === "RUPEE") {
            //         amount += product.retailer_base_plan.cash_value
            //     } else if (product.retailer_base_plan.cash_discount_type === "PERCENTAGE") {
            //         let percent = (amount * product.retailer_base_plan.cash_value) / 100
            //         amount += percent
            //     }
            // } else if (plan === "PLATINUM") {
            //     if (product.retailer_base_plan.platinum_discount_type === "RUPEE") {
            //         amount += product.retailer_base_plan.platinum_value
            //     } else if (product.retailer_base_plan.platinum_discount_type === "PERCENTAGE") {
            //         let percent = (amount * product.retailer_base_plan.platinum_value) / 100
            //         amount += percent
            //     }
            // } else if (plan === "DIAMOND") {
            //     if (product.retailer_base_plan.cash_discount_type === "RUPEE") {
            //         amount += product.retailer_base_plan.cash_value
            //     } else if (product.retailer_base_plan.cash_discount_type === "PERCENTAGE") {
            //         let percent = (amount * product.retailer_base_plan.cash_value) / 100
            //         amount += percent
            //     }
            // } else if (plan === "BRONZE") {
            //     if (product.retailer_base_plan.diamond_discount_type === "RUPEE") {
            //         amount += product.retailer_base_plan.diamond_value
            //     } else if (product.retailer_base_plan.diamond_discount_type === "PERCENTAGE") {
            //         let percent = (amount * product.retailer_base_plan.diamond_value) / 100
            //         amount += percent
            //     }
            // } else if (plan === "GOLD") {
            //     if (product.retailer_base_plan.gold_discount_type === "RUPEE") {
            //         amount += product.retailer_base_plan.gold_value
            //     } else if (product.retailer_base_plan.gold_discount_type === "PERCENTAGE") {
            //         let percent = (amount * product.retailer_base_plan.gold_value) / 100
            //         amount += percent
            //     }
            // }

            tempErr.push({ ...product, qty: product.product_min_quantity || 1, product_selling_price: amount })
        }
        setSelectedProduct([...tempErr])
    }

    // useEffect(() => {
    //     dispatch(CategoryGroup(bazaarID))
    // }, [])

    // useEffect(() => {
    //     if (Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0) {
    //         handleGroupClick(CategoryGroupData[0])
    //     }
    // }, [CategoryGroupData])

    // useEffect(() => {
    //     if (Array.isArray(CategoryData) && CategoryData.length > 0) {
    //         handleCategoryClick(CategoryData[0])
    //     }
    // }, [CategoryData])

    // useEffect(() => {
    //     if (Array.isArray(SubCategoryData) && SubCategoryData.length > 0) {
    //         handleSubCategoryClick(SubCategoryData[0])
    //     }
    // }, [SubCategoryData])

    useEffect(() => {
        // handleSubCategoryClick()
        dispatch(FilteredProductsWholeSeller({ params: { branch: branch } }))
    }, [branch?.id])

    // useEffect(() => {
    //     if (Array.isArray(SubCategoryData) && SubCategoryData.length > 0) {
    //         handleSubCategoryClick(SubCategoryData[0])
    //     }
    // }, [SubCategoryData])

    const handleGroupClick = (data: any) => {
        setselectedType((prevdata: any) => ({ selectedGroup: data }));
        dispatch(clearCategoryState())
        dispatch(clearFilteredProducts())
        dispatch(CategorySlice({ bazaarId: bazaarID, id: data?.id }))
    }

    const handleCategoryClick = (data: any) => {
        setselectedType((prevdata: any) => ({ ...prevdata, selectedCategory: data, selectedSubcategory: undefined }));
        dispatch(clearSubCategoryState())
        dispatch(clearFilteredProducts())
        dispatch(SubCategorySlice({ bazaarId: bazaarID, category: data?.id, id: selectedType['selectedGroup']?.id }))
    }
    const handleSubCategoryClick = () => {
        // setselectedType((prevdata: any) => ({ ...prevdata, selectedSubcategory: data }));
        // dispatch(clearFilteredProducts())
        dispatch(FilteredProductsWholeSeller())
    }

    // const handleSubCategoryClick = (data: any) => {
    //     setselectedType((prevdata: any) => ({ ...prevdata, selectedSubcategory: data }));
    //     dispatch(clearFilteredProducts())
    //     dispatch(FilteredProductsWholeSeller({ params:{bazaar: bazaarID, categoryGroup: selectedType["selectedCategory"]?.category_group, category: selectedType["selectedCategory"]?.id, subCategory: data?.id }}))
    // }

    const handleFilteredProducts = () => {
        dispatch(FilteredProductsWholeSeller({ bazaar: bazaarID, categoryGroup: selectedType["selectedCategory"]?.category_group, category: selectedType["selectedCategory"]?.id, subCategory: selectedType["selectedSubcategory"]?.id }))
    }

    return (
        <>

            <div>
                <ProductDetailModal open={showDetails} handleOpen={setShowDetails} product_id={productdata} />

                <Dialog
                    open={addModalOpen}
                    sx={{
                        ".MuiPaper-root": {
                            borderRadius: "20px",
                            maxWidth: '100%',
                            width: '100%',
                            margin: '0'
                        },
                        maxWidth: '1129px',
                        margin: 'auto'
                    }}
                    onClose={() => setAddModalOpen(false)}
                >
                    <div className="w-full">
                        <div className="pt-[27px] pr-[37px] pb-[23px] pl-[54px] flex gap-5 justify-between">
                            <div className="flex gap-5 justify-between">
                                <p className="font-[600] text-[24px] text-[#2E2C34] line-hight-[20px]">Select Products</p>
                                <SearchField inputdata={setSearch} />
                            </div>
                            <button onClick={() => setAddModalOpen(false)}>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                                    <line x1="24.7305" y1="12.422" x2="12.4268" y2="24.7256" stroke="#84818A" stroke-width="1.5" strokeLinecap="round" />
                                    <line x1="24.3104" y1="24.7266" x2="12.0068" y2="12.4229" stroke="#84818A" stroke-width="1.5" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                        <p className="pr-[37px] pb-[23px] pl-[54px] flex gap-5 text-[16px] font-[600]">{selectedProduct.length} products selected</p>
                        <div className="grid grid-cols-9 gap-[3px]">
                            {/* <div className="bg-[#F5F5F5] col-span-2 ">
                                <div className="py-[16px] px-[30px]">
                                    <p className="text-[12px] font-[700] text-[#2E2C34] tracking-[3px]">GROUP</p>
                                </div>
                                <div className="product_list">
                                    {
                                        CategoryGroupData?.length > 0 && CategoryGroupData?.map((item: any) => (
                                            <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedGroup"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => { handleGroupClick(item) }}>
                                                <div className="flex gap-5 items-center">
                                                    <img
                                                        style={{ width: '32px', height: '32px' }}
                                                        className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                                                        src={item.parent_category_ref_image}
                                                        alt={"Logo"}
                                                    />
                                                    <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedGroup"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.parent_category_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                            <div className="bg-[#F5F5F5] col-span-2 ">
                                <div className="py-[16px] px-[30px]">
                                    <p className="text-[12px] font-[700] text-[#2E2C34] tracking-[3px]">CATEGORY</p>
                                </div>
                                <div className="product_list">
                                    {
                                        CategoryGroupData?.length > 0 && CategoryData?.map((item: any) => (
                                            <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedCategory"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => handleCategoryClick(item)}>
                                                <div className="flex gap-5 items-center">
                                                    <img
                                                        style={{ width: '32px', height: '32px' }}
                                                        className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                                                        src={item?.category_ref_image}
                                                        alt={"Logo"}
                                                    />
                                                    <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedCategory"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.category_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="bg-[#F5F5F5] col-span-2 ">
                                <div className="py-[16px] px-[30px]">
                                    <p className="text-[12px] font-[700] text-[#2E2C34] tracking-[3px]">SUB-CATEGORY</p>
                                </div>
                                <div className="product_list">
                                    {
                                        CategoryData?.length > 0 && SubCategoryData?.length > 0 && SubCategoryData?.map((item: AnyAction) => (
                                            <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedSubcategory"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => handleSubCategoryClick(item)}>
                                                <div className="flex gap-5 items-center">
                                                    <img
                                                        style={{ width: '32px', height: '32px' }}
                                                        className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                                                        src={item?.subcategory_ref_image}
                                                        alt={"Logo"}
                                                    />
                                                    <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedSubcategory"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.subcategory_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> */}

                            <div className="bg-[#ffffff] col-span-9 px-[54px]">
                                <div className="py-[16px] px-[2px]">
                                    <p className="text-[12px] font-[700] text-[#2E2C34] tracking-[3px]">PRODUCTS</p>
                                </div>
                                <div className="product_list">

                                    <div className="overflow-x-auto relative pb-[40px]">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-[12px] text-[#84818A] font-[600] border-b-2">
                                                <tr className=" color-[#2E2C34;] ">
                                                    <th scope="col" className="pb-[28px] pt-[38px]  font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                                                        Select
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                                                        Product Image
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Product Name
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Brand
                                                    </th>
                                                    {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Branch
                      </th> */}
                                                    {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Group Category
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Category
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Sub-Category
                                                    </th> */}
                                                    {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Weight
                      </th> */}
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Total MRP
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Selling Price
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Primary Packaging Unit (Weight)
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Secondary Packaging Unit (Weight)
                                                    </th>
                                                    <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        View
                                                    </th>
                                                    {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                                                        Stock
                                                    </th> */}
                                                    {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] text-center whitespace-nowrap">
                                                        Enable/Disable
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    // FilteredProductsData?.length > 0 && FilteredProductsData?.filter((item: any) => (item?.product_name?.toLowerCase().includes(search?.toLowerCase()) || item?.product_brand_name?.toLowerCase().includes(search?.toLowerCase())) && item.branch_price.find((ele: any) => ele.branch === branch?.id)?.price)?.map

                                                    FilteredProductsData?.length > 0 && FilteredProductsData?.filter((item: any) => (item?.product_name?.toLowerCase().includes(search?.toLowerCase()) || item?.product_brand_name?.toLowerCase().includes(search?.toLowerCase())))?.map((item: any) => (
                                                        <tr className="border-b-2" onClick={() => handleProductSelection(item)}>

                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <div >
                                                                    {selectedProduct.some((itm: any) => itm.id === item.id) ? <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <ellipse cx="9.26579" cy="9" rx="9.19548" ry="9" fill="#FF6652" />
                                                                        <path d="M14.036 9.66732H9.94917H8.58688H4.5V8.33398H8.58688H9.94917H14.036V9.66732Z" fill="white" />
                                                                    </svg> : <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <ellipse cx="9.26579" cy="9" rx="9.19548" ry="9" fill="#4E2FA9" />
                                                                        <path d="M14.036 9.66634H9.94917V13.6663H8.58688V9.66634H4.5V8.33301H8.58688V4.33301H9.94917V8.33301H14.036V9.66634Z" fill="white" />
                                                                    </svg>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <img
                                                                    className="w-[50px] rounded-md"
                                                                    src={item.product_upload_front_image}
                                                                    alt={"Mwb Bazaar"}
                                                                />
                                                            </td>

                                                            <td className="py-4 px-6  color-[#2E2C34;]">{item.product_name}</td>
                                                            {/* <th
                          scope="row"
                          className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div className="flex gap-[20px] items-center ">
                            <img
                              className="w-[50px] rounded-md"
                              src={item.product_upload_mrp_label_image}
                              alt={"Mwb Bazaar"}
                            />
                            <p
                              onClick={() => hendelopenmodel(item)}
                              className=" text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 cursor-pointer m-0"
                            >
                              {item.product_name}
                            </p>
                          </div>
                        </th> */}
                                                            <td className="py-4 px-6  color-[#2E2C34;]">{item.product_brand_name}</td>
                                                            {/* <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_brand_name}
                        </td> */}
                                                            {/* <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <div className="tag">
                                                                    <p className="tagTitle whitespace-nowrap">{item.category_group?.parent_category_name}</p>
                                                                </div>
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <div className="tag">
                                                                    <p className="tagTitle whitespace-nowrap">{item.category?.category_name}</p>
                                                                </div>
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <div className="tag">
                                                                    <p className="tagTitle whitespace-nowrap">{item.subcategory?.subcategory_name}</p>
                                                                </div>
                                                            </td> */}
                                                            {/* <td className="py-4 px-6  color-[#2E2C34;]">{item.product_total_weight_value} {item?.product_per_unit_weight_unit?.unit_name}</td> */}
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                {item.product_total_mrp}
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                {/* {item.branch_price.find((ele:any)=>ele.branch === branch.id).price} */}
                                                                {
                                                                    item.product_selling_price
                                                                }
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                                                                {item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                                                                ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})
                                                            </td>
                                                            <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                                                                {item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                                                                <br />
                                                                ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})
                                                            </td>
                                                            {/* <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <p className="stockStatusTitle whitespace-nowrap">{item.product_stocks} {item?.product_primary_packing_unit?.unit_name}</p>
                                                            </td> */}
                                                            <td className="py-4 px-6  color-[#2E2C34;]">
                                                                <div
                                                                    onClick={() => {
                                                                        setProductdata(item.id)
                                                                        setShowDetails(true)
                                                                    }}
                                                                    className="cursor-pointer"
                                                                >
                                                                    <img src={barIcon} alt={"Logo"} />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* {
                                        FilteredProductsData?.length > 0 && FilteredProductsData?.filter((item: any) => item?.product_name?.toLowerCase().includes(search))?.map((item: any) => (
                                            <div className="py-[9px] cursor-pointer px-[23px]" onClick={() => handleProductSelection(item)}>
                                                <div className="flex gap-5 items-center">
                                                    <div >
                                                        {selectedProduct.some((itm: any) => itm.id === item.id) ? <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <ellipse cx="9.26579" cy="9" rx="9.19548" ry="9" fill="#FF6652" />
                                                            <path d="M14.036 9.66732H9.94917H8.58688H4.5V8.33398H8.58688H9.94917H14.036V9.66732Z" fill="white" />
                                                        </svg> : <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <ellipse cx="9.26579" cy="9" rx="9.19548" ry="9" fill="#4E2FA9" />
                                                            <path d="M14.036 9.66634H9.94917V13.6663H8.58688V9.66634H4.5V8.33301H8.58688V4.33301H9.94917V8.33301H14.036V9.66634Z" fill="white" />
                                                        </svg>
                                                        }
                                                    </div>
                                                    <img
                                                        style={{ width: '36px', height: '36px' }}
                                                        className="rounded-md"
                                                        src={item.product_upload_front_image}
                                                        alt={"Logo"}
                                                    />
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item.product_name}</p>
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item.product_brand_name}</p>
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item.product_total_mrp}</p>
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item.product_selling_price}</p>
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                                                        ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})</p>
                                                    <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                                                        <br />
                                                        ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})</p>
                                                </div>
                                            </div>
                                        ))
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-5 pb-[30px]  pt-[32px] pr-[50px] sticky bottom-0 w-full bg-white justify-end">
                        <ActionButton title="Cancel" variant="default" onClick={() => setAddModalOpen(false)} />
                        <ActionButton title="Continue" variant="primary" onClick={() => hadleSubmitProductSelection()} />
                    </div>

                </Dialog>
            </div>
        </>
    );
};

export default ProductSelect;