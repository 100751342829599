import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { useMatch, useSearchParams } from "react-router-dom";

interface SubMenuItemProps {
  name: string;
  label: string;
  active?: boolean;
  href: string;
}

const SubMenuItem: React.FC<SubMenuItemProps> = (props) => {
  const [active, setActive] = useState(false);

  const navigate = useNavigate();
  const routeMatched = useMatch(props?.href);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    if (props?.href) {
      navigate(props?.href);
    }
  };

  useEffect(() => {
    const item = searchParams.get("content");

    if (item) {
      if (item === props.name) {
        console.log("somethingparam", item, routeMatched);
        setActive(true);
      } else {
        if (routeMatched) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    } else {
      if (routeMatched) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [routeMatched, searchParams]);

  console.log("somethingparam", active, props);

  return (
    <div className={`px-2 py-1.5 cursor-pointer`} onClick={handleClick}>
      <div className={`text-sm  ml-10 ${active ? "font-bold" : "font-medium"}`}>
        {props?.label}
      </div>
    </div>
  );
};

export { SubMenuItem };
