import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import { useMatch } from "react-router-dom";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { SubMenuItem } from "./SubMenuItem";

interface MenuItemProps {
  active?: boolean;
  label: string;
  icon: React.ReactNode;
  href?: string;
  name: string;
  subMenu?: {
    name: string;
    label: string;
    active?: boolean;
    href: string;
  }[];
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const [active, setActive] = useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(active);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routeMatched = useMatch(props?.href ?? "/unknown-rouets-asasd");

  const handleClick = () => {
    if (props?.href) {
      navigate(props?.href);
    } else {
      if (props.subMenu?.length) {
        navigate(props.subMenu[0].href);
      }
    }
    if (props.subMenu?.length) {
      setSubMenuOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (routeMatched) {
      setActive(true);
      if (props.subMenu?.length) {
        setSubMenuOpen(true);
      }
    } else {
      if (props.subMenu?.length) {
        const paths = pathname.split("/");
        props.subMenu.forEach((menu) => {
          if (paths.includes(menu.name)) {
            setActive(true);
            setSubMenuOpen(true);
          }
        });
      }
    }
  }, [routeMatched]);

  useEffect(() => {
    console.log("routes-asd", subMenuOpen);
  }, [subMenuOpen]);

  return (
    <div>
      <div
        className={classNames({
          "menu-item": true,
          active: active,
        })}
        onClick={handleClick}
      >
        <div className="container">
          <div className={"icon"}>{props?.icon}</div>
          <div className={"label"}>{props?.label}</div>
        </div>
        {!!props.subMenu?.length && (
          <ArrowDropDownOutlinedIcon aria-hidden="true" />
        )}
      </div>
      {!!props.subMenu?.length &&
        subMenuOpen &&
        props.subMenu.map((menu, index) => (
          <SubMenuItem key={menu.name + index} {...menu} />
        ))}
    </div>
  );
};

export { MenuItem };
