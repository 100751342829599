import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useEditOrderStyles, useOrderStyles, useTrackOrderStyles } from "@/static/stylesheets/screens";
import basketImg from '../../static/images/basket_image.png'
import { Box, Dialog, FormControl, IconButton, InputLabel, NativeSelect, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos, Download } from "@mui/icons-material";
import { EditCart, EditSubCart, GetOutForDelivery, GetSingleOrderData } from "@/Store/Sclicese/OrderDataSlice";
import { useDispatch, useSelector } from "@/Store";
import { findOrderStatus, findPaymentStatus, getOrderStatusList, orderStatusFlag } from "@/static/data";
import OutForDelivery from "./OutForDelivery";
import UpdateProductStatus from "./UpdateProductStatus";
import moment from "moment";
import { AppService } from "@/service/AllApiData.service";
import { toast } from "react-toastify";


const EditOrderScreen = () => {
  const classes = useEditOrderStyles();
  const classes2 = useOrderStyles();
  const classesTrack = useTrackOrderStyles();

  const data = [1, 2, 3, 4, 5];
  const [addModalOpen, setAddModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // const [quantity, setQuantity] = useState(1);
  const { id } = useParams()
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [outOfDelModal, setOutOfDelModal] = useState(false);
  const [orderStatusMaodal, setOrderStatusMaodal] = useState(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [ordeStatus, setOrderStaus] = useState<any>()
  const [selectForCart, setselectForCart] = useState(true)
  const SingleOrderData: any = useSelector(state => state.SingleOrderData.data);
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoice, setInvoice] = useState<any>();

  const [selectedProduct, setSelectedProduct] = useState<any>({
    id: null,
    name: null,
    qty: null,
    product: null
  })

  const [selectedProductIds, setSelectedProductIds] = useState<any>([])


  const handleSelectedProductIds = (val: any, flag: boolean) => {
    console.log(val, flag, selectForCart)
    let tmpArr = selectedProductIds || [];
    if (flag !== selectForCart) {
      setselectForCart(flag)
      tmpArr = []
    }
    if (val === 'all') {
      if (tmpArr?.length === SingleOrderData?.cart_items?.length) {
        tmpArr = []
      } else {
        tmpArr = SingleOrderData?.cart_items?.map((itm: any) => ({ id: itm.id, qty: itm?.qty }))
      }
    } else {
      if (tmpArr.map((itm: any) => itm.id).includes(val?.id)) {
        tmpArr = tmpArr.filter((itm: any) => itm.id !== val?.id)
      } else {
        tmpArr.push({
          id: val.id,
          qty: val?.qty
        })
      }
    }
    console.log(tmpArr, selectedProductIds)
    setSelectedProductIds([...tmpArr])
  }



  const handleModalClose = () => {
    setUpdateStatusModal(false)
    setSelectedProduct({
      id: null,
      name: null,
      qty: null,
      product: null
    })
  }

  const handleSetItem = (item: any) => {
    setUpdateStatusModal(true)
    setSelectedProduct({
      id: item.id,
      name: item.product?.product_name,
      qty: item?.qty,
      product: item.product
    })
  }

  useEffect(() => {
    dispatch(GetSingleOrderData(id))
    // dispatch(GetOutForDelivery())
  }, [])

  const handleIncrement = () => {
    if (selectedProduct?.qty) {
      setSelectedProduct({
        ...selectedProduct,
        qty: selectedProduct?.qty + 1
      })
    }
  };

  const handleDecrement = () => {
    if (selectedProduct?.qty > 1) {
      setSelectedProduct({
        ...selectedProduct,
        qty: selectedProduct?.qty - 1
      })
    }
  };
console.log(SingleOrderData,'SingleOrderData');

  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    // Initialize quantities based on cart_items
    if (SingleOrderData?.cart_items) {
      const initialQuantities: { [key: number]: number } = {};
      SingleOrderData.cart_items.forEach((item: any) => {
        initialQuantities[item.id] = item?.qty;
      });
      setQuantities(initialQuantities);
    }
  }, [SingleOrderData]);

  const handleDecrease = (itemId: number) => {
    setQuantities(prevQuantities => {
      const currentQuantity = prevQuantities[itemId] || 0; // Use 0 if undefined
      const updatedQuantity = Math.max(currentQuantity - 1, 1); // Ensure minimum is 1
      return { ...prevQuantities, [itemId]: updatedQuantity };
    });
  }

  const handleIncrease = (itemId: number, originalQty: number) => {
    setQuantities(prevQuantities => {
      const currentQuantity = prevQuantities[itemId] || 0; // Use 0 if undefined
      const updatedQuantity = Math.min(currentQuantity + 1, originalQty); // Ensure maximum is originalQty
      return { ...prevQuantities, [itemId]: updatedQuantity };
    });
  }

  const removeItem = async (subCardid: any) => {
    try {
      const response = await AppService.deleteSubCart(subCardid);
      dispatch(GetSingleOrderData(id));
      toast.success('Success');
    } catch (err) {
      console.log("ERROR ====> ", err)
    }
  }

  const isPending = SingleOrderData?.order_status === orderStatusFlag.PENDING

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div>
            <div className="flex gap-5 align-center">
              <div onClick={() => navigate('/order')}>
                <ArrowBackIos style={{ color: '#84818A', cursor: 'pointer' }} />
              </div>
              <p className="commonTitle flex justify-start items-center">
                <span>
                  #Order ID-{SingleOrderData?.order_id}
                </span>
                <span className="text-lg mx-5">
                  <span className={`py-[4px] px-[8px] text-[12px] font-semibold rounded bg-[${findOrderStatus(SingleOrderData?.order_status).bgColor}] text-[${findOrderStatus(SingleOrderData?.order_status).color}]`}>{findOrderStatus(SingleOrderData?.order_status).label}</span>
                </span>
              </p>
            </div>

            <div className="bg-[#f8f8f8] py-[8px] flex flex-row mt-[40px] mb-5">
              <div className="flex gap-5">
                <div className="px-5">
                  <p className=" text-[#84818A] text-[12px]">Date</p>
                  <p className=" text-[#000] text-[13px]">{moment(SingleOrderData?.order_created_at).format("DD/MM/YYYY")}</p>
                </div>
                {/* <div className="px-5">
                  <p>Firm Name</p>
                  <p>{SingleOrderData?.wholeseller?.wholeseller_firm_name}</p>
                </div>
                <div className="px-5">
                  <p>Contact Person Name</p>
                  <p>{SingleOrderData?.wholeseller?.wholeseller_contact_per}</p>
                </div>
                <div className="px-5">
                  <p>Contact Number</p>
                  <p>{SingleOrderData?.wholeseller?.wholeseller_number}</p>
                </div>
                <div className="px-5">
                  <p>City</p>
                  <p>{SingleOrderData?.wholeseller?.wholeseller_city?.city}</p>
                </div> */}
                <div className="px-5">
                  <p className=" text-[#84818A] text-[12px]">Order Type</p>
                  <p className=" text-[#000] text-[13px]">{SingleOrderData?.payment_type}</p>
                </div>

                <div className="px-5">
                  <p className=" text-[#84818A] text-[12px]">Payment Status</p>
                  <p className=" text-[#000] text-[13px]">{SingleOrderData?.payment_status}</p>
                </div>

                <div className="px-5">
                  <p className=" text-[#84818A] text-[12px]">Number of Items</p>
                  <p className=" text-[#000] text-[13px]">{SingleOrderData?.total_items}</p>
                </div>

                <div className="px-5">
                  <p className=" text-[#84818A] text-[12px]">Total Amount</p>
                  <p className=" text-[#000] text-[13px]">{SingleOrderData?.payment_amount} ₹</p>
                </div>

              </div>
              {/* <div className="px-5">
                <p className="font-black text-xl">Total Amount</p>
              </div>
              <div className="px-5">
                <p className="font-black text-xl">{SingleOrderData?.payment_amount} ₹</p>
              </div> */}
            </div>

            <div>
              <div className="overflow-x-auto relative pb-[40px]">
                <table className="w-full text-sm text-left dark:text-gray-400">
                  <thead className="text-[14px] text-[#84818A] font-[600]]">
                    <tr className="border-b color-[#2E2C34;]">
                      <th>
                        {
                          !isPending &&
                          <input type="checkbox" onChange={() => handleSelectedProductIds('all', true)} checked={selectedProductIds?.length === SingleOrderData?.cart_items?.length && selectForCart} />
                        }
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Sr. No
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Image
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Product Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Brand
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Offers
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Unit Price
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Total Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      {/* <th scope="col" className="py-3 px-6">
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="text-[#2E2C34">
                    {SingleOrderData?.cart_items?.filter((item:any)=>item.qty)?.map((item: any, index: number) => (
                      <tr className="border-b" key={index}>
                        <td>
                          {
                            !isPending &&
                            <input type="checkbox" checked={selectedProductIds?.map((itm: any) => itm.id)?.includes(item?.id) && selectForCart} onChange={() => handleSelectedProductIds(item, true)} />
                          }
                        </td>
                        <td className="py-4 px-6">
                          {index + 1}
                        </td>
                        <td className="py-4 px-6">
                          <img
                            className="w-[50px] rounded-sm"
                            src={"https://api.mwbbazaar.com/media" + item.product?.product_upload_front_image?.split("media")[1]}
                            alt='basketImg' />
                        </td>
                        <td
                          scope="row"
                          className="py-4 px-6"
                        >
                          <div className="flex gap-[20px] items-center ">
                            <p
                              onClick={() => setAddModalOpen(true)}
                              className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 whitespace-nowrap"
                              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                            >
                              {item.product?.product_name}
                            </p>
                          </div>
                        </td>
                        <td className="py-4 px-6 whitespace-nowrap">
                          {item.product?.product_brand_name}
                        </td>
                        <td className="py-4 px-6">

                        </td>
                        <td scope="row" className="py-4 px-6 cursor-pointer">
                          <div className="flex align-center">
                            {/* <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{width:'80px'}}> */}
                            {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleDecrement}>-</button> */}
                            <span>{item?.qty}</span>
                            {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleIncrement}>+</button> */}
                            {/* </div> */}
                            {/* <Typography sx={{fontWeight:'700',fontSize:'14px',color:'#4E2FA9',display:'flex',alignItems:'center',marginLeft:'2px'}}>Save</Typography> */}
                          </div>
                        </td>
                        <td className="py-4 px-6 cursor-pointer">
                          <p className="font-[14px] color-[#4E2FA9]">
                            {item?.amount} ₹
                          </p>
                        </td>
                        <td className="py-4 px-6 cursor-pointer">
                          <p className="cursor-pointer">{item?.amount * item?.qty} ₹</p>
                        </td>
                        {/* <td className="py-4 px-6 cursor-pointer">
                          <p className="cursor-pointer">{item?.amount * item?.qty} ₹</p>
                        </td> */}
                        {!isPending && <td>
                          <div className={`bg-[${findOrderStatus(item.status)?.bgColor}] flex justify-center rounded-md p-[10px]`}>
                            <p
                              // onClick={() => setAnotherAddModalOpen(true)}
                              className={`cursor-pointer text-[${findOrderStatus(item.status)?.color}] whitespace-nowrap `}
                            >
                              {findOrderStatus(item.status)?.label}
                            </p>
                          </div>
                        </td>}
                        {
                          isPending && <>
                            <td className="py-4 px-6 cursor-pointer">
                              <div className="flex align-center" onClick={() => removeItem(item.id)}>
                                <div>
                                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6248 7.66699L12.5013 10.7905L9.37776 7.66699L7.66797 9.37678L10.7915 12.5003L7.66797 15.6239L9.37776 17.3337L12.5013 14.2101L15.6248 17.3337L17.3346 15.6239L14.2111 12.5003L17.3346 9.37678L15.6248 7.66699ZM12.5013 0.416992C5.82526 0.416992 0.417969 5.82428 0.417969 12.5003C0.417969 19.1764 5.82526 24.5837 12.5013 24.5837C19.1773 24.5837 24.5846 19.1764 24.5846 12.5003C24.5846 5.82428 19.1773 0.416992 12.5013 0.416992ZM12.5013 22.167C7.17255 22.167 2.83464 17.8291 2.83464 12.5003C2.83464 7.17158 7.17255 2.83366 12.5013 2.83366C17.8301 2.83366 22.168 7.17158 22.168 12.5003C22.168 17.8291 17.8301 22.167 12.5013 22.167Z" fill="#FF6652" />
                                  </svg>
                                </div>
                                <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#FF6652', marginLeft: '6px' }}>Remove </Typography>
                              </div>
                            </td>
                            <td className="py-4 px-6 cursor-pointer">
                              <div className="flex align-center">
                                <div>
                                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 15.4596V18.4996C0 18.7796 0.22 18.9996 0.5 18.9996H3.54C3.67 18.9996 3.8 18.9496 3.89 18.8496L14.81 7.93957L11.06 4.18957L0.15 15.0996C0.0500001 15.1996 0 15.3196 0 15.4596ZM17.71 5.03957C18.1 4.64957 18.1 4.01957 17.71 3.62957L15.37 1.28957C14.98 0.89957 14.35 0.89957 13.96 1.28957L12.13 3.11957L15.88 6.86957L17.71 5.03957Z" fill="#4E2FA9" />
                                  </svg>

                                </div>
                                <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#4E2FA9', marginLeft: '6px' }} onClick={() => handleSetItem(item)} >Edit Quantity </Typography>
                              </div>
                            </td>
                          </>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              {isPending && <ActionButton title="Accept Order" onClick={() => {
                dispatch(EditCart({
                  id: SingleOrderData?.id,
                  order_status: "APPROVED",
                  order_status_change_at: new Date().toJSON()
                }))
              }} />}
              {!isPending && <ActionButton title="Update Status" disable={selectedProductIds.length ? false : true} onClick={() => {
                setOrderStaus(getOrderStatusList(SingleOrderData?.order_status)[0].value)
                setOrderStatusMaodal(true)
                // dispatch(EditCart({
                //   id: SingleOrderData?.id,
                //   order_status: "APPROVED",
                //   order_status_change_at: new Date().toJSON()
                // }))
              }} />}
            </div>

            {Array.isArray(SingleOrderData?.out_for_deliver_product) && SingleOrderData?.out_for_deliver_product?.length > 0 && <div>
              <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                <h3 className=" text-[20px] font-bold mb-5">Out For Delivery</h3>
                <table className="w-full text-sm text-left dark:text-gray-400">
                  <thead className="text-[14px] text-[#84818A] font-[600]]">
                    <tr className="border-b color-[#2E2C34;]">
                      <th>
                        {/* <input type="checkbox" onChange={() => handleSelectedProductIds('all',false)} checked={selectedProductIds?.length === SingleOrderData?.cart_items?.length && !selectForCart} /> */}
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Sr. No
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Image
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Product Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Brand
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Offers
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Unit Price
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Total Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Bill
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      {/* <th scope="col" className="py-3 px-6">

                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="text-[#2E2C34">
                    {
                      Array.isArray(SingleOrderData?.out_for_deliver_product) && SingleOrderData?.out_for_deliver_product?.map((gtitm: any) => {
                        return gtitm?.product_delivery_arrays?.map((pditem: any, index: number) => {
                          const item = SingleOrderData?.cart_items?.filter((im: any) => im.id === pditem.sub_cart)[0]
                          if (!item) {
                            return ''
                          }
                          return (
                            <tr className="border-b" key={index}>
                              <td>
                                {/* <input type="checkbox" checked={selectedProductIds?.map((itm: any) => itm.id)?.includes(item?.id) && !selectForCart} onChange={() => handleSelectedProductIds(item,false)} /> */}
                              </td>
                              <td className="py-4 px-6">
                                {index + 1}
                              </td>
                              <td className="py-4 px-6 whitespace-nowrap">
                                {moment(gtitm?.bill_created_at).format("DD-MM-YYYY")}
                              </td>
                              <td className="py-4 px-6">
                                <img
                                  className="w-[50px] rounded-sm"
                                  src={"https://api.mwbbazaar.com/media" + item.product?.product_upload_front_image?.split("media")[1]}
                                  alt='basketImg' />
                              </td>
                              <td
                                scope="row"
                                className="py-4 px-6"
                              >
                                <div className="flex gap-[20px] items-center ">
                                  <p
                                    onClick={() => setAddModalOpen(true)}
                                    className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 whitespace-nowrap"
                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                                  >
                                    {item.product?.product_name}
                                  </p>
                                </div>
                              </td>
                              <td className="py-4 px-6 whitespace-nowrap">
                                {item.product?.product_brand_name}
                              </td>
                              <td className="py-4 px-6">

                              </td>
                              <td scope="row" className="py-4 px-6 cursor-pointer">
                                <div className="flex align-center">
                                  {/* <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{width:'80px'}}> */}
                                  {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleDecrement}>-</button> */}
                                  <span>{pditem?.quantity}</span>
                                  {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleIncrement}>+</button> */}
                                  {/* </div> */}
                                  {/* <Typography sx={{fontWeight:'700',fontSize:'14px',color:'#4E2FA9',display:'flex',alignItems:'center',marginLeft:'2px'}}>Save</Typography> */}
                                </div>
                              </td>
                              <td className="py-4 px-6 cursor-pointer">
                                <p className="font-[14px] color-[#4E2FA9]">
                                  {item?.amount} ₹
                                </p>
                              </td>
                              <td className="py-4 px-6 cursor-pointer">
                                <p className="cursor-pointer">{item?.amount * Number(pditem?.quantity)} ₹</p>
                              </td>
                              <td className="py-4 px-6 cursor-pointer">
                                <IconButton onClick={() => window.open(gtitm.image)} >
                                  <Download className="text-[#4E2FA9]" />
                                </IconButton>
                              </td>
                              {!isPending && <td>
                                <div className={`bg-[${findOrderStatus("OUTFORDELIVERY")?.bgColor}] flex justify-center rounded-md p-[10px]`}>
                                  <p
                                    // onClick={() => setAnotherAddModalOpen(true)}
                                    className={`cursor-pointer text-[${findOrderStatus("OUTFORDELIVERY")?.color}]`}
                                  >
                                    {findOrderStatus("OUTFORDELIVERY")?.label}
                                  </p>
                                </div>
                              </td>}
                              {
                                isPending && <>
                                  <td className="py-4 px-6 cursor-pointer">
                                    <div className="flex align-center">
                                      <div>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M15.6248 7.66699L12.5013 10.7905L9.37776 7.66699L7.66797 9.37678L10.7915 12.5003L7.66797 15.6239L9.37776 17.3337L12.5013 14.2101L15.6248 17.3337L17.3346 15.6239L14.2111 12.5003L17.3346 9.37678L15.6248 7.66699ZM12.5013 0.416992C5.82526 0.416992 0.417969 5.82428 0.417969 12.5003C0.417969 19.1764 5.82526 24.5837 12.5013 24.5837C19.1773 24.5837 24.5846 19.1764 24.5846 12.5003C24.5846 5.82428 19.1773 0.416992 12.5013 0.416992ZM12.5013 22.167C7.17255 22.167 2.83464 17.8291 2.83464 12.5003C2.83464 7.17158 7.17255 2.83366 12.5013 2.83366C17.8301 2.83366 22.168 7.17158 22.168 12.5003C22.168 17.8291 17.8301 22.167 12.5013 22.167Z" fill="#FF6652" />
                                        </svg>
                                      </div>
                                      <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#FF6652', marginLeft: '6px' }}>Remove </Typography>
                                    </div>
                                  </td>
                                  <td className="py-4 px-6 cursor-pointer">
                                    <div className="flex align-center">
                                      <div>
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 15.4596V18.4996C0 18.7796 0.22 18.9996 0.5 18.9996H3.54C3.67 18.9996 3.8 18.9496 3.89 18.8496L14.81 7.93957L11.06 4.18957L0.15 15.0996C0.0500001 15.1996 0 15.3196 0 15.4596ZM17.71 5.03957C18.1 4.64957 18.1 4.01957 17.71 3.62957L15.37 1.28957C14.98 0.89957 14.35 0.89957 13.96 1.28957L12.13 3.11957L15.88 6.86957L17.71 5.03957Z" fill="#4E2FA9" />
                                        </svg>

                                      </div>
                                      <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#4E2FA9', marginLeft: '6px' }} onClick={() => handleSetItem(item)} >Edit Quantity </Typography>
                                    </div>
                                  </td>
                                </>
                              }
                            </tr>
                          )
                        })
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>}



            {Array.isArray(SingleOrderData?.cart_items?.filter((item: any) => item?.status === orderStatusFlag.SUCCESS)) && SingleOrderData?.cart_items?.filter((item: any) => item?.status === orderStatusFlag.SUCCESS)?.length > 0 && <div>
              <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                <h3 className=" text-[20px] font-bold mb-5">Delivered</h3>
                <table className="w-full text-sm text-left dark:text-gray-400">
                  <thead className="text-[14px] text-[#84818A] font-[600]]">
                    <tr className="border-b color-[#2E2C34;]">
                      <th>
                        {/* <input type="checkbox" onChange={() => handleSelectedProductIds('all',false)} checked={selectedProductIds?.length === SingleOrderData?.cart_items?.length && !selectForCart} /> */}
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Sr. No
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Image
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Product Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Brand
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Offers
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Unit Price
                      </th>
                      <th scope="col" className="py-3 px-6 whitespace-nowrap">
                        Total Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Invoice
                      </th>
                      {/* <th scope="col" className="py-3 px-6">

                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="text-[#2E2C34">
                    {
                      Array.isArray(SingleOrderData?.cart_items?.filter((item: any) => item?.status === orderStatusFlag.SUCCESS)) && SingleOrderData?.cart_items?.filter((item: any) => item?.status === orderStatusFlag.SUCCESS)?.map((item: any, index: number) => {
                        // return gtitm?.product_delivery_arrays?.map((pditem: any, index: number) => {
                        // const item = SingleOrderData?.cart_items?.filter((im: any) => im.id === pditem.sub_cart)[0]
                        // if (!item) {
                        //   return ''
                        // }
                        return (
                          <tr className="border-b" key={index}>
                            <td>
                              {/* <input type="checkbox" checked={selectedProductIds?.map((itm: any) => itm.id)?.includes(item?.id) && !selectForCart} onChange={() => handleSelectedProductIds(item,false)} /> */}
                            </td>
                            <td className="py-4 px-6">
                              {index + 1}
                            </td>
                            <td className="py-4 px-6">
                              <img
                                className="w-[50px] rounded-sm"
                                src={"https://api.mwbbazaar.com/media" + item.product?.product_upload_front_image?.split("media")[1]}
                                alt='basketImg' />
                            </td>
                            <td
                              scope="row"
                              className="py-4 px-6"
                            >
                              <div className="flex gap-[20px] items-center ">
                                <p
                                  onClick={() => setAddModalOpen(true)}
                                  className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 whitespace-nowrap"
                                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                                >
                                  {item.product?.product_name}
                                </p>
                              </div>
                            </td>
                            <td className="py-4 px-6 whitespace-nowrap">
                              {item.product?.product_brand_name}
                            </td>
                            <td className="py-4 px-6">

                            </td>
                            <td scope="row" className="py-4 px-6 cursor-pointer">
                              <div className="flex align-center">
                                {/* <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{width:'80px'}}> */}
                                {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleDecrement}>-</button> */}
                                <span>{item?.qty}</span>
                                {/* <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleIncrement}>+</button> */}
                                {/* </div> */}
                                {/* <Typography sx={{fontWeight:'700',fontSize:'14px',color:'#4E2FA9',display:'flex',alignItems:'center',marginLeft:'2px'}}>Save</Typography> */}
                              </div>
                            </td>
                            <td className="py-4 px-6 cursor-pointer">
                              <p className="font-[14px] color-[#4E2FA9]">
                                {item?.amount} ₹
                              </p>
                            </td>
                            <td className="py-4 px-6 cursor-pointer">
                              <p className="cursor-pointer">{item?.amount * item?.qty} ₹</p>
                            </td>
                            {/* <td className="py-4 px-6 cursor-pointer">
                              <p className="cursor-pointer">{item?.amount * item?.qty} ₹</p>
                            </td> */}
                            {!isPending && <td>
                              <div className={`bg-[${findOrderStatus(item.status)?.bgColor}] flex justify-center rounded-md p-[10px]`}>
                                <p
                                  // onClick={() => setAnotherAddModalOpen(true)}
                                  className={`cursor-pointer text-[${findOrderStatus(item.status)?.color}] whitespace-nowrap`}
                                >
                                  {findOrderStatus(item.status)?.label}
                                </p>
                              </div>
                            </td>}
                            {
                              isPending && <>
                                <td className="py-4 px-6 cursor-pointer">
                                  <div className="flex align-center">
                                    <div>
                                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.6248 7.66699L12.5013 10.7905L9.37776 7.66699L7.66797 9.37678L10.7915 12.5003L7.66797 15.6239L9.37776 17.3337L12.5013 14.2101L15.6248 17.3337L17.3346 15.6239L14.2111 12.5003L17.3346 9.37678L15.6248 7.66699ZM12.5013 0.416992C5.82526 0.416992 0.417969 5.82428 0.417969 12.5003C0.417969 19.1764 5.82526 24.5837 12.5013 24.5837C19.1773 24.5837 24.5846 19.1764 24.5846 12.5003C24.5846 5.82428 19.1773 0.416992 12.5013 0.416992ZM12.5013 22.167C7.17255 22.167 2.83464 17.8291 2.83464 12.5003C2.83464 7.17158 7.17255 2.83366 12.5013 2.83366C17.8301 2.83366 22.168 7.17158 22.168 12.5003C22.168 17.8291 17.8301 22.167 12.5013 22.167Z" fill="#FF6652" />
                                      </svg>
                                    </div>
                                    <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#FF6652', marginLeft: '6px' }}>Remove </Typography>
                                  </div>
                                </td>
                                <td className="py-4 px-6 cursor-pointer">
                                  <div className="flex align-center">
                                    <div>
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 15.4596V18.4996C0 18.7796 0.22 18.9996 0.5 18.9996H3.54C3.67 18.9996 3.8 18.9496 3.89 18.8496L14.81 7.93957L11.06 4.18957L0.15 15.0996C0.0500001 15.1996 0 15.3196 0 15.4596ZM17.71 5.03957C18.1 4.64957 18.1 4.01957 17.71 3.62957L15.37 1.28957C14.98 0.89957 14.35 0.89957 13.96 1.28957L12.13 3.11957L15.88 6.86957L17.71 5.03957Z" fill="#4E2FA9" />
                                      </svg>

                                    </div>
                                    <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#4E2FA9', marginLeft: '6px' }} onClick={() => handleSetItem(item)} >Edit Quantity </Typography>
                                  </div>
                                </td>

                              </>
                            }
                            <td className="text-[#4E2FA9] underline cursor-pointer text-center" onClick={() => {
                              setInvoice(item);
                              setShowInvoice(true)
                            }} >
                              Invoice
                            </td>
                          </tr>
                        )
                        // })
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>}


          </div>
        </div>
      </DashboardLayout>

      {
        selectedProduct &&
        <Dialog
          open={showInvoice}
          maxWidth={"lg"}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "20px",
            },
          }}
          onClose={() => {
            setShowInvoice(false);
            setInvoice({});
          }}
        >
          <div className={classes2.updateStatusModal}>
            <div className="">
              <p className="text-[#84818A] text-sm closeBtn left-7 w-auto whitespace-nowrap">Invoice #{SingleOrderData?.order_id}</p>
              <span
                className="closeBtn"
                onClick={() => {
                  setShowInvoice(false);
                  setInvoice({});
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="13"
                    cy="13"
                    r="12.5"
                    fill="white"
                    stroke="#FF6652"
                  />
                  <path
                    d="M17.0977 9.74414L9.74375 17.0981"
                    stroke="#FF6652"
                    stroke-width="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M9.74609 9.74414L17.1 17.0981"
                    stroke="#FF6652"
                    stroke-width="2"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            </div>
            <div className=" overflow-y-auto">
              <div className="flex justify-between items-center mt-[50px]">
                <div className="flex items-center">
                  <div className=" w-12 h-12 bg-black rounded-xl flex justify-center items-center">
                    {/* <p className="text-[25px] font-bold text-white">W</p> */}
                    <img src={"https://api.mwbbazaar.com/media" + SingleOrderData?.wholeseller?.wholeseller_image.split('media')[1]} />
                  </div>
                  <div className="ml-3">
                    <h3 className=" text-[20px] font-bold text=[#2E2C34] m-0">{SingleOrderData?.wholeseller?.wholeseller_firm_name}</h3>
                    <p className=" text-[16px] font-normal text-[#84818A] leading-5">13 Nov 2019</p>
                  </div>
                </div>

                <div className={`bg-[${findPaymentStatus(invoice?.payment_status)?.bgColor}] text-[${findPaymentStatus(invoice?.payment_status)?.color}] flex justify-center rounded-md py-[4px] px-[8px] w-[max-content]`}>
                  <p
                    className="cursor-pointer font-semibold text-[12px] m-0"
                  >
                    {findPaymentStatus(invoice?.payment_status)?.label}
                  </p>
                </div>
              </div>

              <div className="flex justify-between items-start mt-5">

                <div className="flex flex-col items-start">

                  <p className="text-[#84818A] text-[12px] font-bold">From:</p>

                  <p className="text-[#84818A] text-[14px] font-bold my-[19px]">{SingleOrderData?.wholeseller?.wholeseller_firm_name}</p>

                  <div>
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">{SingleOrderData?.wholeseller?.wholeseller_address} <br /> {SingleOrderData?.wholeseller?.wholeseller_landmark} <br /> {SingleOrderData?.wholeseller?.wholeseller_city?.city}, {SingleOrderData?.wholeseller?.wholeseller_district?.district}, {SingleOrderData?.wholeseller?.wholeseller_state?.state}</p>
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">GST Number: {SingleOrderData?.wholeseller?.wholeseller_gst_no}</p>
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">{SingleOrderData?.wholeseller?.wholeseller_email_id}</p>
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">{SingleOrderData?.wholeseller?.wholeseller_number}</p>
                  </div>

                </div>

                <div className="flex flex-col items-end">

                  <p className="text-[#84818A] text-[12px] font-bold">Bill to:</p>

                  <p className="text-[#84818A] text-[14px] font-bold my-[19px]">{SingleOrderData?.retailer?.retailer_name}</p>

                  <div className="flex flex-col items-end">
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal text-end">{SingleOrderData?.retailer?.retailer_address} <br /> {SingleOrderData?.retailer?.retailer_landmark} <br /> {SingleOrderData?.retailer?.retailer_city?.city}, {SingleOrderData?.retailer?.retailer_district?.district}, {SingleOrderData?.retailer?.retailer_state?.state}</p>
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">GST Number: {SingleOrderData?.retailer?.retailer_gst_no}</p>
                    {/* <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">{SingleOrderData?.retailer?.retailer_state?.state}</p> */}
                    <p className=" text=[#2E2C34] text-[14px] leading-[21px] font-normal">{SingleOrderData?.retailer?.retailer_number?.retailer_number}</p>
                  </div>

                </div>
              </div>


              <div className="w-full h-[1px] bg-[#EEE] my-5">
              </div>

              <h3 className="text-[#2E2C34] text-[18px] font-bold leading-[27px]">Description</h3>

              <table className="w-full mt-5">
                <thead className="bg-[#F6F6F6] rounded-[6.5px] overflow-hidden">
                  <th className="text-[#84818A] text-[12px] font-bold leading-[18px] py-[11px] px-[14px] text-left">Product Name</th>
                  <th className="text-[#84818A] text-[12px] font-bold leading-[18px] py-[11px] text-left px-[14px] ">Brand Name</th>
                  <th className="text-[#84818A] text-[12px] font-bold leading-[18px] py-[11px] text-left px-[14px] ">Quantity</th>
                  <th className="text-[#84818A] text-[12px] font-bold leading-[18px] py-[11px] text-left px-[14px] ">Unit Price</th>
                  <th className="text-[#84818A] text-[12px] font-bold leading-[18px] py-[11px] text-left px-[14px] ">Total Price</th>
                </thead>
                <tbody>
                  <td className=" text-[#2E2C34] text-[14px] font-normal leading-[21px] py-[8px] text-left px-[14px] ">{invoice?.product?.product_name}</td>
                  <td className=" text-[#2E2C34] text-[14px] font-normal leading-[21px] py-[8px] px-[14px] ">{invoice?.product?.product_brand_name}</td>
                  <td className=" text-[#2E2C34] text-[14px] font-normal leading-[21px] py-[8px] px-[14px] ">{invoice?.qty}</td>
                  <td className=" text-[#2E2C34] text-[14px] font-normal leading-[21px] py-[8px] px-[14px] ">Rs.{invoice?.amount}</td>
                  <td className=" text-[#2E2C34] text-[14px] font-normal leading-[21px] py-[8px] px-[14px] ">Rs.{invoice?.amount * invoice?.qty}</td>

                </tbody>
              </table>

              <div className="w-[208px] ml-auto flex flex-col gap-4 mt-5">

                <div className="flex justify-between items-center">
                  <p className="text-[#1C1D21] text-[14px] font-bold leading-[21px]">Subtotal</p>
                  <p className="text-[#1C1D21] text-[14px] font-normal leading-[21px]">Rs. {Number((invoice?.amount * invoice?.qty) - ((invoice?.amount * invoice?.qty) * (Number(invoice?.product?.product_gst_no) / 100))).toFixed(2)}</p>
                </div>

                <div className="flex justify-between items-center">
                  <p className="text-[#1C1D21] text-[14px] font-bold leading-[21px]">GST{invoice?.product?.product_gst_no}%</p>
                  <p className="text-[#1C1D21] text-[14px] font-normal leading-[21px]">Rs.{Number((invoice?.amount * invoice?.qty) * (Number(invoice?.product?.product_gst_no) / 100)).toFixed(2)}</p>
                </div>

                <div className="flex justify-between items-center">
                  <p className="text-[#1C1D21] text-[14px] font-bold leading-[21px]">Total</p>
                  <p className="text-[#1C1D21] text-[14px] font-bold leading-[21px]">Rs.{invoice?.amount * invoice?.qty}</p>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      }

      <Dialog
        open={updateStatusModal}
        maxWidth={"lg"}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
        onClose={() => handleModalClose()}
      >
        <div className={classes2.updateStatusModal}>
          <p className="title">Update Product Quantity</p>
          <span
            className="closeBtn"
            onClick={() => handleModalClose()}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="13"
                cy="13"
                r="12.5"
                fill="white"
                stroke="#FF6652"
              />
              <path
                d="M17.0977 9.74414L9.74375 17.0981"
                stroke="#FF6652"
                stroke-width="2"
                strokeLinecap="round"
              />
              <path
                d="M9.74609 9.74414L17.1 17.0981"
                stroke="#FF6652"
                stroke-width="2"
                strokeLinecap="round"
              />
            </svg>
          </span>

          <div className="modalContent">
            <div className="selectStatus">
              <Box sx={{ minWidth: 120, width: 300 }}>
                <table className="text-sm text-left dark:text-gray-400">
                  <thead className="text-[14px] text-[#84818A] font-[600]]">
                    <tr className="border-b color-[#2E2C34;]">
                      <th scope="col" className="py-3 px-6">
                        Product Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[#2E2C34">
                    <tr className="border-b">

                      <td
                        scope="row"
                        className="py-4 px-6"
                      >
                        <div className="flex gap-[20px] items-center ">
                          <p
                            className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400"
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                          >
                            {selectedProduct?.name}
                          </p>
                        </div>
                      </td>
                      <td scope="row" className="py-4 px-6 cursor-pointer">
                        <div className="flex align-center">
                          <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{ width: '80px' }}>
                            <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleDecrement}>-</button>
                            <span>{selectedProduct?.qty}</span>
                            <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={handleIncrement}>+</button>
                          </div>
                          {/* <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#4E2FA9', display: 'flex', alignItems: 'center', marginLeft: '2px' }}>Save</Typography> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </div>
            <div className="nextButton flex align-center justify-center">
              <button onClick={() => {
                dispatch(EditSubCart({
                  id: selectedProduct?.id,
                  qty: selectedProduct?.qty,
                  // status:"OUTFORDELIVERY",
                  product: selectedProduct?.product?.id,
                  "used_in_cart": true,
                  orderId: id
                }))
              }}>Update</button>
            </div>
          </div>
        </div>
      </Dialog>

      {outOfDelModal && <OutForDelivery selectedOrder={SingleOrderData} outOfDelModal={outOfDelModal} setOutOfDelModal={setOutOfDelModal} selectedProducts={selectedProductIds} quantites={quantities} reload={() => {
        setQuantities({});
        dispatch(GetSingleOrderData(id))
        // dispatch(GetOutForDelivery())
      }} />
      }

      <div>
        <Dialog
          open={orderStatusMaodal}
          maxWidth={"lg"}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "20px",
            },
          }}
          onClose={() => setOrderStatusMaodal(false)}
        >
          <div className={classesTrack.updateStatusModal}>
            <p className="title">Update the status</p>
            <span
              className="closeBtn"
              onClick={() => setOrderStatusMaodal(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="13"
                  cy="13"
                  r="12.5"
                  fill="white"
                  stroke="#FF6652"
                />
                <path
                  d="M17.0977 9.74414L9.74375 17.0981"
                  stroke="#FF6652"
                  stroke-width="2"
                  strokeLinecap="round"
                />
                <path
                  d="M9.74609 9.74414L17.1 17.0981"
                  stroke="#FF6652"
                  stroke-width="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>

            <div className="modalContent">
              <div className="selectStatus">
                <Box sx={{ minWidth: 120, width: 300 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Order Status
                    </InputLabel>
                    <NativeSelect
                      // defaultValue={"delivery"}
                      onChange={(e) => {
                        console.log()
                        setOrderStaus(e.target.value)
                      }}
                      value={ordeStatus}
                    // inputProps={{
                    //   name: "age",
                    //   id: "uncontrolled-native",
                    // }}
                    >
                      {/* <option value={''}>select order status</option> */}

                      {
                        getOrderStatusList(SingleOrderData?.order_status)?.map(os => {
                          return <option value={os.value}>{os.label}</option>
                        })
                      }
                    </NativeSelect>

                  </FormControl>
                </Box>
              </div>

              <div className="amountBoxWrapper">
                {SingleOrderData?.cart_items?.filter((itm: any) => selectedProductIds?.some((im: any) => im?.id === itm?.id)).map((item: any, index: number) => {
                  const itemId = item.id;
                  const originalQty = item?.qty;
                  const quantity = quantities[itemId] !== undefined ? quantities[itemId] : originalQty;
                  const isDisabled = quantity === originalQty;

                  return (
                    <div className="products" key={index}>
                      {/* <img src={productImg} alt="productImg" /> */}
                      <div className="productName">
                        {item?.product?.product_name}
                      </div>

                      <div className="amountBtns">
                        <button onClick={() => handleDecrease(itemId)}>-</button>
                        <p>{quantity}</p>
                        <button disabled={isDisabled} onClick={() => handleIncrease(itemId, originalQty)}>+</button>
                      </div>
                    </div>)
                })}
              </div>

              <div className="nextButton">
                <button onClick={ async () => {

                  if ([orderStatusFlag.APPROVED, orderStatusFlag.REJECTED, orderStatusFlag.INPROGRESS, orderStatusFlag.SUCCESS].includes(ordeStatus)) {

                    if (selectedProductIds.length) {

                      SingleOrderData?.cart_items?.filter((itm: any) => selectedProductIds?.some((im: any) => im?.id === itm?.id)).map(async(item: any, index: number) => {
                       await  dispatch(EditSubCart({
                          id: item?.id,
                          status: ordeStatus,
                        }))
                      })

                    } else {

                      SingleOrderData?.cart_items?.forEach( async (scitm: any) => {
                       await  dispatch(EditSubCart({
                          id: scitm?.id,
                          status: ordeStatus,
                          // order_status_change_at: new Date().toJSON()
                        }))
                      })
                    }

                    let outForDeliveryLength = SingleOrderData?.cart_items.filter((item: any, index: number) => item.status === "OUTFORDELIVERY")

                    let deliveredLength = SingleOrderData?.cart_items.filter((item: any, index: number) => item.status === "SUCCESS")

                    console.log("Logger123...", outForDeliveryLength, deliveredLength)

                    if (SingleOrderData?.cart_items === deliveredLength) {

                     await dispatch(EditCart({
                        id: SingleOrderData?.id,
                        order_status: "SUCCESS",
                        order_status_change_at: new Date().toJSON()
                      }))

                    } else if (SingleOrderData?.cart_items === outForDeliveryLength) {

                     await dispatch(EditCart({
                        id: SingleOrderData?.id,
                        order_status: "OUTFORDELIVERY",
                        order_status_change_at: new Date().toJSON()
                      }))

                    } else if (deliveredLength) {
                     await dispatch(EditCart({
                        id: SingleOrderData?.id,
                        order_status: "PARTIALDELIVERED",
                        order_status_change_at: new Date().toJSON()
                      }))
                    } else if (outForDeliveryLength) {

                     await dispatch(EditCart({
                        id: SingleOrderData?.id,
                        order_status: "PARTIALPENDING",
                        order_status_change_at: new Date().toJSON()
                      }))
                    }

                    await dispatch(GetSingleOrderData(id))
                    // setUpdateStatusModal(false)
                    setOrderStatusMaodal(false)

                  }

                  if (ordeStatus === orderStatusFlag.OUTFORDELIVERY) {
                    setOrderStatusMaodal(false);
                    setOutOfDelModal(true)
                  }
                }}>Next</button>
              </div>
            </div>
          </div>
        </Dialog >
      </div >
    </>
  );
};

export default EditOrderScreen;
