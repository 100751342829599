import { DashboardLayout } from "@/components/layouts";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import UploadAreaImage from "@/static/svg/ic_uploadarea.svg";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import { getWholeSellerId } from "@/helper";

export default function BankDetails() {
  const [payload, setPayload] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    let params = {
      wholeseller: getWholeSellerId(),
    };
    AppService.getBank(params)
      .then((response) => {
        setPayload(response.data.results[0]);
        console.log("BankDetailsrun", response.data.results[0]);
      })
      .catch((err) => {
        console.log("ERROR ====> ", err);
      });
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="w-full">
          <div className="flex align-middle justify-between">
            <p className=" text-[22px] font-bold flex items-center">
              Bank Details
            </p>
            <button
              className="btn btn-primary bg-[#ff6652] text-white px-5 py-2 rounded-md"
              onClick={() => navigate("/bank-details/add")}
            >
              Add Payment Details
            </button>
          </div>

          {
            <div className="max-w-[500px] rounded overflow-hidden shadow-lg mx-auto mt-[50px]">
              <div className="p-6 grid grid-cols-1 gap-3">
                <div className="flex items-center">
                  <p className="text-gray-700 font-bold text-base">
                    Account Number
                  </p>
                  <p className="text-gray-700 text-base ml-3">
                    : {payload?.account_number ?? "--  --"}
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="text-gray-700 font-bold text-base">IFSC Code</p>
                  <p className="text-gray-700 text-base ml-3">
                    : {payload?.ifsc_code ?? "--  --"}
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="text-gray-700 font-bold text-base">
                    Account Name
                  </p>
                  <p className="text-gray-700 text-base ml-3">
                    : {payload?.account_name ?? "--  --"}
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="text-gray-700 font-bold text-base">Bank Name</p>
                  <p className="text-gray-700 text-base ml-3">
                    : {payload?.bank_name ?? "--  --"}
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="text-gray-700 font-bold text-base">
                    Phone Number
                  </p>
                  <p className="text-gray-700 text-base ml-3">
                    : {payload?.phone_number ?? "--  --"}
                  </p>
                </div>
              </div>
              <div className="p-6  w-full">
                {!!payload?.qr_code_1 && (
                  <div>
                    <p className="text-gray-700 font-bold text-base w-full">
                      QR code 1
                    </p>
                    {payload?.qr_code_1 && (
                      <img
                        className="mx-auto"
                        src={
                          "https://api.mwbbazaar.com/media" +
                          payload?.qr_code_1?.split("media")[1]
                        }
                      />
                    )}
                    <p className="text-gray-700 text-base text-center">
                      {payload?.upi_id_1 ?? "N/A"}
                    </p>
                  </div>
                )}

                {!!payload?.qr_code_2 && (
                  <div>
                    <p className="text-gray-700 font-bold text-base w-full">
                      QR code 2
                    </p>
                    {payload?.qr_code_2 && (
                      <img
                        className="mx-auto"
                        src={
                          "https://api.mwbbazaar.com/media" +
                          payload?.qr_code_2?.split("media")[1]
                        }
                      />
                    )}
                    <p className="text-gray-700 text-base text-center">
                      {payload?.upi_id_2 ?? "N/A"}
                    </p>
                  </div>
                )}

                {!!payload?.qr_code_3 && (
                  <div>
                    <p className="text-gray-700 font-bold text-base w-full">
                      QR code 3
                    </p>
                    {payload?.qr_code_3 && (
                      <img
                        className="mx-auto"
                        src={
                          "https://api.mwbbazaar.com/media" +
                          payload?.qr_code_3?.split("media")[1]
                        }
                      />
                    )}
                    <p className="text-gray-700 text-base text-center">
                      {payload?.upi_id_3 ?? "N/A"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </DashboardLayout>
    </>
  );
}
