import React, { useEffect, useState } from "react";
import { useAuthenticationLayoutStyles } from "@/static/stylesheets/layouts";
import backIcon from "@/static/icons/ic_back_icon.png";
import { any, string } from "prop-types";

interface OtpFormProps {
  onGoBack?(): void;
  onSuccess?(obj: any): void;
  otp?: any;
  userNumber?: string;
}

const OtpForm: React.FC<OtpFormProps> = (props) => {
  const classes = useAuthenticationLayoutStyles();
  const [otpvar, setotpvar] = useState(false);
  const [obj, setobj]: any = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const inputdata = (e: any) => {
    obj[e.target.name] = e.target.value;
    setobj({ ...obj });
    props.otp(obj);

    if (
      obj.input1 !== "" &&
      obj.input2 !== "" &&
      obj.input3 !== "" &&
      obj.input4 !== "" &&
      obj.input5 !== "" &&
      obj.input6 !== ""
    ) {
      setotpvar(true);
    } else {
      // setotpvar(false)
      setotpvar(true);
    }
  };

  const digitValidate = function (ele: any) {
    ele.value = ele.value.replace(/[^0-9]/g, "");
  };

  const tabChange = function (val: number) {
    let ele = document.querySelectorAll("input");
    if (ele.length != val) {
      if (ele[val - 1].value != "") {
        ele[val].focus();
      }
    }
    if (ele[val - 1].value == "") {
      if (!(val - 1 < 1)) {
        ele[val - 2].focus();
      }
    }
  };

  return (
    <>
      <div className={classes.otpTitle}>
        <span className={"back-button"} onClick={props?.onGoBack}>
          <img src={backIcon} alt={"Go Back"} />
        </span>
        OTP Verification
      </div>
      <div className={classes.hint}>
        Enter the code we sent to the mobile number ending in{" "}
        {props?.userNumber?.substring(props?.userNumber?.length - 4)}
      </div>

      <div className={classes.otpInputContainer}>
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input1"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(1)}
        />
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input2"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(2)}
        />
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input3"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(3)}
        />
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input4"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(4)}
        />
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input5"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(5)}
        />
        <input
          type={"text"}
          maxLength={1}
          onChange={inputdata}
          name="input6"
          onInput={(e) => digitValidate(e.target)}
          onKeyUp={() => tabChange(6)}
        />
      </div>
      {!otpvar ? (
        <span className="text-[red] font-[400] text-[14px]">
          * Enter valid OTP
        </span>
      ) : (
        ""
      )}
      <div className={classes.submitButtonContainer}>
        <button
          className={classes.submitButton}
          onClick={() => props.onSuccess && props.onSuccess(obj)}
        >
          Verify
        </button>
      </div>
    </>
  );
};

export { OtpForm };
