import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import {
  CategorySlice,
  clearCategoryState,
} from "@/Store/Sclicese/CategorySlice";
import {
  SubCategorySlice,
  clearSubCategoryState,
} from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  GetCategoryListPlan,
  GetCategoryPlan,
  SubCategoryListPlan,
  SubCategoryPlan,
} from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
// import ItemRow from "./ItemRow";
import { SectionHeader } from "@/components/molecules/Bazaars";
import UserProduts from "@/Store/Sclicese/WholesellerProducts";
import { FilteredProductsWholeSeller } from "@/Store/Sclicese/filteredProductsSlice";
import { AppService } from "@/service/AllApiData.service";
import { getUserId, getWholeSellerId } from "@/helper";

const planTypes = [
  {
    type: "cash_discount_type",
    value: "cash_value",
  },
  {
    type: "platinum_discount_type",
    value: "platinum_value",
  },
  {
    type: "diamond_discount_type",
    value: "diamond_value",
  },
  {
    type: "gold_discount_type",
    value: "gold_value",
  },
  {
    type: "bronze_discount_type",
    value: "bronze_value",
  },
];

export default function ViewProductPricings() {
  const classes = useBranchPlanStyles();
  const wholesellerproductdata = useSelector(
    (state) => state.Wholesellerproduct.data
  );
  const GetCategoryPlanListData: any = useSelector<any>(
    (state) => state?.GetCategoryListPlanData?.data
  );
  const SubCategoryPlanListData: any = useSelector<any>(
    (state) => state?.SubCategoryListPlanData?.data
  );
  const GetCategoryPlanData: any = useSelector<any>(
    (state) => state?.GetCategoryPlanData?.data?.results
  );
  const SubCategoryPlanData: any = useSelector<any>(
    (state) => state?.SubCategoryPlanData?.data?.results
  );
  const ItemWisePlanData: any = useSelector<any>(
    (state) => state?.ItemWisePlanData?.data?.results
  );
  const GetBranch: any = useSelector<any>(
    (state) => state?.GetBranch?.data?.results
  );
  const [CategoryValue, setCategoryValue] = React.useState<string[]>([
    "All_Category",
  ]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch();
  const localStoragedata = JSON.parse(
    localStorage.getItem("Wholeseller_id") || ""
  );
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "");
  const CategoryGroupData = useSelector(
    (state) => state?.CategoryGroup?.data?.results
  );

  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setselectedType] = useState<any>({});
  const ProductBazaar = useSelector(
    (state) => state?.ProductBazaarList?.data?.results
  );
  const CategoryData = useSelector(
    (state) => state?.CategoryData?.data?.results
  );
  const SubCategoryData = useSelector(
    (state) => state?.SubCategoryData?.data?.results
  );
  const FilteredProductsData = useSelector(
    (state) => state?.FilteredProductsReducer?.data?.results
  );
  const MasterRetailerTypeData = useSelector(
    (state) => state?.MasterRetailerTypeData.data
  );
  const [productPricings, setProductPricings] = useState<any>([]);
  const [filterData, setFilterData] = useState({
    group_category: null,
    category: null,
    sub_category: null,
    retailer: null,
    branch: null,
    mode: "Category",
  });

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getProductPricings = async (param: any = {}) => {
    let params = {
      ...param,
      retailer_type_id: filterData.retailer,
      branch_id: filterData.branch,
    };
    const response = await AppService.getProductPlans(getUserId(), params);
    console.log("logger123...", response);
    setProductPricings(response.data.results);
    setTotalCount(response?.data?.results?.length);
  };

  useEffect(() => {
    let params = {};
    if (filterData.group_category) {
      params = {
        ...params,
        category_group: filterData.group_category,
      };
    }

    if (filterData.category) {
      params = {
        ...params,
        category: filterData.category,
      };
    }

    if (filterData.sub_category) {
      params = {
        ...params,
        subcategory: filterData.sub_category,
      };
    }

    if (filterData.retailer && filterData.branch) {
      getProductPricings(params);
    }
  }, [filterData.retailer, filterData, bazaarId]);

  const handlePageChange = async (e: any, p: any) => {
    setCurrentPage(p);
  };

  useEffect(() => {
    console.log("logger123...", FilteredProductsData);
  }, [FilteredProductsData]);

  useEffect(() => {
    dispatch(FilteredProductsWholeSeller());
  }, []);

  useEffect(() => {
    dispatch(GetBranchList(localStoragedata)).then((e: any) => {});
    dispatch(MasterRetailerType());
    dispatch(ItemWisePlan());
  }, []);

  useEffect(() => {
    dispatch(CategoryGroup(bazaarId));
  }, []);

  useEffect(() => {
    if (CategoryValue.includes("All_Category")) {
      let array: any = [];
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z);
          }
        });
      });
      setMapData(array);
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = [];
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z);
          }
        });
      });
      setMapData(array);
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item]);
      });
    }
  }, [
    CategoryValue,
    SubCategoryValue,
    ItemWiseValue,
    GetCategoryPlanListData,
    GetCategoryPlanData,
  ]);

  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id,
  }));

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id,
  }));

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id,
  }));

  const branchFilterOptions = GetBranch?.map((item: any) => ({
    label: item?.branch_name,
    value: item?.id,
  }));

  useEffect(() => {
    if (
      Array.isArray(MasterRetailerTypeData) &&
      MasterRetailerTypeData.length > 0
    ) {
      setFilterData({
        ...filterData,
        retailer: MasterRetailerTypeData[0]?.id,
      });
    }

    if (Array.isArray(GetBranch) && GetBranch.length > 0) {
      setFilterData({
        ...filterData,
        branch: GetBranch.filter((brn: any) => brn.main_branch)[0]?.id,
      });
    }
  }, [MasterRetailerTypeData, GetBranch]);

  const handleChangeMaster = (selectboxName: string, id: any) => {
    if (selectboxName === "Group Category") {
      dispatch(clearCategoryState());
      dispatch(clearSubCategoryState());
      dispatch(CategorySlice({ bazaarId: bazaarId, id }));
      setFilterData({
        ...filterData,
        group_category: id,
        category: null,
        mode: "Category",
      });
    }
    if (selectboxName === "Category") {
      dispatch(clearSubCategoryState());
      dispatch(
        SubCategorySlice({
          bazaarId: bazaarId,
          category: id,
          id: filterData.group_category,
        })
      );
      setFilterData({
        ...filterData,
        category: id,
        mode: "Sub Category",
      });
    }
    if (selectboxName === "Sub Category") {
      setFilterData({
        ...filterData,
        sub_category: id,
      });
    }

    if (selectboxName === "Branch") {
      setFilterData({
        ...filterData,
        branch: id,
      });
    }
  };

  useEffect(() => {
    dispatch(UserProduts({ branch: filterData.branch }));
  }, [filterData.branch]);

  useEffect(() => {
    if (MasterRetailerTypeData.length)
      setFilterData((prevData) => ({
        ...prevData,
        retailer: MasterRetailerTypeData[0]?.id,
      }));
  }, [MasterRetailerTypeData]);

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <SectionHeader />
          <div>
            <div className="flex gap-5 mb-[20px] items-center">
              <p className="py-[10px] font-semibold text-2xl">
                View Product Pricing
              </p>

              {branchFilterOptions?.length > 0 && (
                <CommonSelectElectronicBazaar
                  label={"Branch"}
                  hint={"Select Branch"}
                  options={branchFilterOptions}
                  handleSelect={handleChangeMaster}
                  selectedIds={branchFilterOptions
                    ?.filter((item: any) => filterData.branch == item?.value)
                    .map((elm: any) => elm?.value)}
                  showSelectedLable
                />
              )}
            </div>

            <div className="flex gap-5 py-[20px]">
              {MasterRetailerTypeData?.map((item: any) => (
                <ActionButton
                  title={item?.retailer_type_name}
                  variant={
                    item.id === filterData.retailer ? "primary" : "default"
                  }
                  onClick={() =>
                    setFilterData({ ...filterData, retailer: item?.id })
                  }
                />
              ))}
            </div>

            <div className="flex justify-between mt-5">
              <div className="flex gap-5">
                {groupCategoryFilterOptions?.length > 0 && (
                  <CommonSelectElectronicBazaar
                    label={"Group Category"}
                    hint={"Select Group Category"}
                    options={groupCategoryFilterOptions}
                    handleSelect={handleChangeMaster}
                    selectedIds={groupCategoryFilterOptions
                      ?.filter(
                        (item: any) => filterData.group_category == item?.value
                      )
                      .map((elm: any) => elm?.value)}
                    colorcode={"#4e2fa9"}
                    showSelectedLable
                  />
                )}
                {categoryFilterOptions?.length > 0 && (
                  <CommonSelectElectronicBazaar
                    label={"Category"}
                    hint={"Select Category"}
                    options={categoryFilterOptions}
                    handleSelect={handleChangeMaster}
                    selectedIds={categoryFilterOptions
                      ?.filter(
                        (item: any) => filterData.category == item?.value
                      )
                      .map((elm: any) => elm?.value)}
                    colorcode={"#4e2fa9"}
                    showSelectedLable
                  />
                )}
                {subCategoryFilterOptions?.length > 0 && (
                  <CommonSelectElectronicBazaar
                    label={"Sub Category"}
                    hint={"Select Sub Category"}
                    options={subCategoryFilterOptions}
                    handleSelect={handleChangeMaster}
                    selectedIds={subCategoryFilterOptions
                      ?.filter(
                        (item: any) => filterData.sub_category == item?.value
                      )
                      .map((elm: any) => elm?.value)}
                    // colorcode={'#4e2fa9'}
                  />
                )}
              </div>
              <div>{/* <SearchField icontype/> */}</div>
            </div>

            <div>
              <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] ">
                    <tr className="tableTitle color-[#2E2C34;]">
                      <th scope="col" className="py-3 px-3">
                        PRODUCT NAME
                      </th>
                      <th scope="col" className="py-3 px-6">
                        CASH
                      </th>
                      <th scope="col" className="py-3 px-6">
                        PLATINUM
                      </th>
                      <th scope="col" className="py-3 px-6">
                        DIAMOND
                      </th>
                      <th scope="col" className="py-3 px-6">
                        GOLD
                      </th>
                      <th scope="col" className="py-3 px-6">
                        BRONZE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productPricings?.map((item: any) => (
                      <tr className="border-b align-baseline">
                        <th
                          scope="row"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                        >
                          <div className="flex gap-[20px] items-center ">
                            <p className="mb-3 text-[14px] font-[600] text-black font-Manrope cursor-pointer">
                              {item?.product_name}
                            </p>
                          </div>
                        </th>

                        <td className="py-4 px-6 color-[#2E2C34;]">
                          <p className="text-[14px] font-medium font-[600] text-black">
                            {" "}
                            {item?.plan_based_selling_price?.cash_value
                              ? "₹" + item?.plan_based_selling_price?.cash_value
                              : "--"}
                          </p>
                        </td>

                        <td className="py-4 px-6 color-[#2E2C34;]">
                          <p className="text-[14px] font-medium font-[600] text-black">
                            {" "}
                            {item?.plan_based_selling_price?.platinum_value
                              ? "₹" +
                                item?.plan_based_selling_price?.platinum_value
                              : "--"}
                          </p>
                        </td>

                        <td className="py-4 px-6 color-[#2E2C34;]">
                          <p className="text-[14px] font-medium font-[600] text-black">
                            {" "}
                            {item?.plan_based_selling_price?.diamond_value
                              ? "₹" +
                                item?.plan_based_selling_price?.diamond_value
                              : "--"}
                          </p>
                        </td>

                        <td className="py-4 px-6 color-[#2E2C34;]">
                          <p className="text-[14px] font-medium font-[600] text-black">
                            {" "}
                            {item?.plan_based_selling_price?.gold_value
                              ? "₹" + item?.plan_based_selling_price?.gold_value
                              : "--"}
                          </p>
                        </td>

                        <td className="py-4 px-6 color-[#2E2C34;]">
                          <p className="text-[14px] font-medium font-[600] text-black">
                            {" "}
                            {item?.plan_based_selling_price?.bronze_value
                              ? "₹" +
                                item?.plan_based_selling_price?.bronze_value
                              : "--"}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="w-full h-[60px] bg-white"
                style={{ boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)" }}
              >
                <div className="flex justify-between items-center w-full h-full px-[39px] py-2">
                  <p className="text-[#84818A] font-[Manrope] text-[12px] font-[600]">
                    Show {productPricings.length} from {totalCount} users
                  </p>
                  <div>
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={currentPage}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "#84818A",
                            fontFamily: "Manrope",
                            fontSize: "12px",
                            fontWeight: 600,
                          },
                          "& .Mui-selected": {
                            backgroundColor:
                              "rgba(85, 66, 246, 0.1) !important",
                            color: "rgba(85, 66, 246, 1)",
                          },
                        }}
                        shape="rounded"
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}
