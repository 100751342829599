import { useDispatch } from "@/Store";
import {
  CreateOutForDelivery,
  EditCart,
  EditSubCart,
} from "@/Store/Sclicese/OrderDataSlice";
import { GridOptionButton } from "@/components/atoms/Button";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { DashboardLayout } from "@/components/layouts";
import { UploadArea } from "@/components/organisms/UploadArea";
import { UploadAreaCustom } from "@/components/organisms/UploadArea/UploadAreaCustom";
import { orderStatusFlag } from "@/static/data";
import Payment_Receipt from "@/static/images/Payment_Receipt.jpg";
import productImg from "@/static/images/productImg.jpg";
import { useTrackOrderStyles } from "@/static/stylesheets/screens";
import ContactIcon from "@/static/svg/ic_contactuser.svg";
import information from "@/static/svg/ic_information.svg";
import { Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from "yup";

type propsType = {
  selectedOrder: any;
  outOfDelModal: any;
  setOutOfDelModal: any;
  selectedProducts: any;
  reload?: any;
  quantites?: any;
};

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const OutForDelivery = ({
  selectedOrder,
  outOfDelModal,
  setOutOfDelModal,
  selectedProducts,
  reload,
  quantites,
}: propsType) => {
  const classes = useTrackOrderStyles();
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();

  console.log(
    "SELECTED  ORDER ====> ",
    selectedOrder,
    selectedProducts,
    quantites
  );

  const [payload, setPayload] = useState({
    retailer: selectedOrder?.retailer?.id,
    cart: selectedOrder?.id,
    wholeseller: selectedOrder?.wholeseller?.id,
    product_delivery_arrays: selectedProducts?.map((itm: any) => ({
      sub_cart: itm.id,
      quantity: quantites?.[itm?.id] || 0,
    })),
    image: null,
    bill_created_at: moment().format("YYYY-MM-DD"),
    bill_number: selectedOrder?.order_id,
    // "bill_amount": selectedOrder?.cart_items?.filter((item:any) => selectedProducts?.map((im:any)=>im.id).includes(item.id))?.reduce((a:any,b:any)=>{
    //   return a + (b.product.product_selling_price * b.qty)
    // },0),
    bill_amount: 0,
    deliver_date: null,
    driver_number: null,
    driver_name: null,
    driver_vehicle_number: null,
    current_address: null,
    is_delivery_address_same_as_current_address: true,
    landmark: null,
    state: null,
    city: null,
    pincode: null,
    get_delivery_address_location_json_data: null,
    id: 6,
  });

  useEffect(() => {
    let totalPrice = 0;
    for (let i = 0; i < selectedProducts?.length; i++) {
      let qty = quantites?.[selectedProducts[i].id] || 0;
      let price = selectedOrder?.cart_items.find(
        (ele: any) => ele?.id === selectedProducts[i].id
      ).amount;
      totalPrice += qty * price;
    }
    setPayload({
      ...payload,
      bill_amount: totalPrice,
    });
  }, [quantites]);

  const handleSubmit = async (values: any) => {
    let order_status = orderStatusFlag.OUTFORDELIVERY;
    console.log("payload", payload, values, selectedOrder, selectedProducts);
    // return;
    let body = values;

    if (selectedProducts?.length) {
      body.product_delivery_arrays = selectedProducts?.map((itm: any) => ({
        sub_cart: itm.id,
        quantity: quantites[itm.id],
      }));
    }

    let res = await dispatch(CreateOutForDelivery(values));

    console.log(res);

    console.log("logger123...", selectedProducts, selectedOrder, quantites);

    let selectedOrderList = JSON.parse(JSON.stringify(selectedOrder));

    if (selectedProducts?.length) {
      selectedOrder?.cart_items
        ?.filter((itm: any) =>
          selectedProducts?.some((im: any) => im?.id === itm?.id)
        )
        .map(async (item: any, index: number) => {
          let orderIndex = selectedOrderList?.cart_items.findIndex(
            (ele: any) => ele.id === item.id
          );
          selectedOrderList.cart_items[orderIndex].status =
            Number(item.qty) > Number(quantites[item.id])
              ? "PARTIALPENDING"
              : order_status;

          await dispatch(
            EditSubCart({
              id: item?.id,
              status:
                Number(item.qty) > Number(quantites[item.id])
                  ? "PARTIALPENDING"
                  : order_status,
              qty: Number(item.qty) - Number(quantites[item.id]),
            })
          );
        });
    } else {
      selectedOrder?.cart_items?.forEach(async (scitm: any) => {
        let orderIndex = selectedOrderList?.cart_items.findIndex(
          (ele: any) => ele.id === scitm.id
        );
        selectedOrderList.cart_items[orderIndex].status = order_status;

        await dispatch(
          EditSubCart({
            id: scitm?.id,
            status: order_status,
          })
        );
      });
    }

    let outForDeliveryLength = selectedOrderList?.cart_items.filter(
      (item: any, index: number) => item.status === "OUTFORDELIVERY"
    ).length;

    let partiallyPendingLength = selectedOrderList?.cart_items.filter(
      (item: any, index: number) => item.status === "PARTIALPENDING"
    ).length;

    let deliveredLength = selectedOrderList?.cart_items.filter(
      (item: any, index: number) => item.status === "DELIVERED"
    ).length;

    console.log(
      "LOGGER123...",
      outForDeliveryLength,
      deliveredLength,
      partiallyPendingLength,
      selectedOrderList,
      selectedOrder
    );

    if (selectedOrder?.cart_items.length === deliveredLength) {
      await dispatch(
        EditCart({
          id: selectedOrder?.id,
          order_status: "DELIVERED",
          order_status_change_at: new Date().toJSON(),
        })
      );
    } else if (selectedOrder?.cart_items.length === outForDeliveryLength) {
      await dispatch(
        EditCart({
          id: selectedOrder?.id,
          order_status: "OUTFORDELIVERY",
          order_status_change_at: new Date().toJSON(),
        })
      );
    } else if (deliveredLength) {
      await dispatch(
        EditCart({
          id: selectedOrder?.id,
          order_status: "PARTIALDELIVERED",
          order_status_change_at: new Date().toJSON(),
        })
      );
    } else if (partiallyPendingLength) {
      await dispatch(
        EditCart({
          id: selectedOrder?.id,
          order_status: "PARTIALOUTFORDELIVERY",
          order_status_change_at: new Date().toJSON(),
        })
      );
    }

    setOutOfDelModal(false);
    reload();
  };

  const handleChangeMasterType = async (e: any, handler: any) => {
    const { files, value, name } = e.target;
    if (files) {
      await getBase64(files[0]).then((data) =>
        // setPayload((prev: any) => ({ ...prev, [name]: data }))
        handler({ target: { name, value: data } })
      );
    } else if (Number(value)) {
      // payload[name] = Number(value)
      // setPayload((prev: any) => ({ ...prev, [name]: Number(value) }))
      handler({ target: { name, value: Number(value) } });
    } else {
      // setPayload((prev: any) => ({ ...prev, [name]: value }))
      handler({ target: { name, value: value } });
    }
  };
  console.log(payload, selectedOrder);

  const formSchema = Yup.object({
    image: Yup.string().required("Bill is required *"),
    driver_number: Yup.string()
      .matches(
        /^[0-9]{10}$/,
        "Invalid mobile number. It should be exactly 10 digits."
      )
      .required("Mobile number is required *"),
    driver_vehicle_number: Yup.string().required(
      "Driver vehicle number is required *"
    ),
    current_address: Yup.string().required("Current address is required *"),
    driver_name: Yup.string().required("Driver name is required *"),
  });

  return (
    <>
      {/* Out of Delivery Modal */}
      <Formik
        initialValues={payload}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
          handleSubmit,
        }) => (
          <div>
            <Dialog
              open={outOfDelModal}
              maxWidth={"lg"}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "20px",
                },
              }}
              onClose={() => setOutOfDelModal(false)}
            >
              <div className={classes.outOfDelModal}>
                <p className="title">Update the status</p>
                <span
                  className="closeBtn"
                  onClick={() => setOutOfDelModal(false)}
                >
                  x
                </span>

                {/* <form action="#" method="post" className="modalContent form"> */}
                <div className="uploadPicute relative">
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => handleChangeMasterType(e, handleChange)}
                    className="absolute w-full h-full cursor-pointer opacity-0"
                  />
                  <h6 className="InputHeading">Upload Picture</h6>
                  <UploadAreaCustom
                    label={"Upload Image"}
                    img={values?.image as unknown as string}
                  />
                  <Box
                    sx={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    <ErrorMessage name="image" />
                  </Box>
                </div>

                <div className="billDetails mt-5">
                  <h6 className="InputHeading">Bill Details</h6>
                  <div className="billForm subForm">
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Bill Date"
                        type="date"
                        variant="standard"
                        disabled
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="bill_created_at"
                        value={values.bill_created_at}
                      />
                    </div>

                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Order Number"
                        multiline
                        type="number"
                        disabled
                        variant="standard"
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="bill_number"
                        value={values.bill_number}
                      />
                    </div>
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Bill Amouunt"
                        multiline
                        type="number"
                        variant="standard"
                        // onChange={handleChangeMasterType}
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="bill_amount"
                        value={values.bill_amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="driverDetails mt-5">
                  <h6 className="InputHeading">Driver Details</h6>
                  <div className="driverForm subForm">
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Driver Name"
                        multiline
                        type="number"
                        variant="standard"
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="driver_name"
                        value={values.driver_name}
                      />
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        <ErrorMessage name="driver_name" />
                      </Box>
                    </div>
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Driver Number"
                        multiline
                        variant="standard"
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="driver_number"
                        value={values.driver_number}
                        inputProps={{ maxLength: 10 }} // Set maximum length to 10 characters
                      />

                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        <ErrorMessage name="driver_number" />
                      </Box>
                    </div>
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Driver Vehicle Number"
                        multiline
                        type="number"
                        variant="standard"
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="driver_vehicle_number"
                        value={values.driver_vehicle_number}
                      />
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        <ErrorMessage name="driver_vehicle_number" />
                      </Box>
                    </div>
                  </div>
                </div>

                <div className="addressDetails mt-5">
                  <h6 className="InputHeading">Address Details</h6>
                  <div className="addressForm subForm">
                    <div className="formControl">
                      <TextField
                        sx={{ width: 250 }}
                        label="Current Address"
                        multiline
                        type="number"
                        variant="standard"
                        onChange={(e) =>
                          handleChangeMasterType(e, handleChange)
                        }
                        name="current_address"
                        value={values.current_address}
                      />
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        <ErrorMessage name="current_address" />
                      </Box>
                    </div>

                    {/* <button className="newAddressBtn">
                    <span>+</span>
                    Add New Address
                  </button> */}
                  </div>
                </div>

                <div className="submitButton">
                  <div
                    className="formControl mt-5"
                    onClick={() => setChecked(!checked)}
                  >
                    <input type="checkbox" checked={checked} />
                    <p>Delivery address is same as current address</p>
                  </div>
                  <button onClick={() => handleSubmit()}>
                    Send for Delivery
                  </button>
                </div>
                {/* </form> */}
              </div>
            </Dialog>
          </div>
        )}
      </Formik>
    </>
  );
};

export default OutForDelivery;
