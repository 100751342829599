import React from "react";
import { useSwitchStyles } from "@/static/stylesheets/atoms";

interface SwitchProps {
  actives?: boolean,
  onChange?:any,
  name?:any
}

const Switch: React.FC<SwitchProps> = (props) => {
  const classes = useSwitchStyles();
console.log('====================================');
console.log(props.actives,"props.actives");
console.log('====================================');
  return (
    <>
      <span className={classes.root}>
        <label className={"switch"}>
          <input type="checkbox"  checked={props.actives} onChange={props.onChange}/>
          <span className="slider round"></span>
        </label>
      </span>
    </>
  );
};

export { Switch };
