import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { useNavigate } from "react-router-dom";

interface Iprops {
  arrow?: string;
  title?: string;
  amount?: string;
  href?: string;
}

function DashboardCard(props: Iprops) {
  const navigate = useNavigate();

  return (
    <div>
      <p className="font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] py-[12px] tracking-[1.5px]">
        {props.title}
      </p>
      <p className="!text-[#2E2C34] tracking-[1px] font-Manrope font-[700] text-[24px] leading-[32px] text-[#84818A]">
        {props.amount}
      </p>
      {!!props?.href && (
        <button
          onClick={() => (props?.href ? navigate(props.href) : null)}
          className={`h-[16px] w-[16px] bg-[#${
            props?.arrow == "down" ? "FC3400" : "20C9AC"
          }] flex p-[3px] absolute bottom-[28px] right-[24px]`}
        >
          {props?.arrow == "down" ? (
            <SouthEastIcon className="text-[white] !w-[100%] !h-[100%] font-[700]" />
          ) : (
            <NorthEastIcon className="text-[white] !w-[100%] !h-[100%] font-[700]" />
          )}
        </button>
      )}
    </div>
  );
}

export default DashboardCard;
