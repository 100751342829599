import React, { useState, useEffect } from "react";
import { usePaymentdetailsStyle } from "@/static/stylesheets/molecules/paymentdetailsStyle";
import {
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
} from "@mui/material";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import UploaderFrame from "@/static/icons/uploader-frame.png";
import { useNavigate, useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { DashboardLayout } from "@/components/layouts";
import { CheckBox } from "@mui/icons-material";
import { getWholeSellerId } from "@/helper";

// interface PaymentDetailsProps {
//   setActiveStep?: any
// }

export default function BuyPlay() {
  const classes = usePaymentdetailsStyle();
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = React.useState("CASH");
  const [addModalOpen, setAddModalOpen] = useState(false);
  // const [planId] = useState(localStorage.getItem("planId"));
  const [plandata, setPalndata] = useState<any>({});
  const [data, setdata] = useState<any>({});
  const [showerroremail, setshowerroremail] = useState<any>(false);
  const [emaildata, setEmailData] = useState<any>("");
  const [showerrorphonenumber, setshowerrorphonenumber] = useState<any>(false);
  const [phonenumberdata, setphonenumberData] = useState<any>("");
  const [showerroramount, setshowerroramount] = useState<any>(false);
  const [amountdata, setamountData] = useState<any>("");
  const { planId, type } = useParams();
  const [applyFromNow, setApplyFromNow] = useState(false);

  useEffect(() => {
    getPlanData();
    getWholesellerData();
  }, []);

  const getPlanData = async () => {
    try {
      const res = await AppService.getPlanById(planId);
      setPalndata(res.data);
    } catch (error: any) {
      console.log("err", error.message);
    }
  };

  const subscribeFreePlan = async () => {
    let body = {
      payment_choice: paymentType,
      paid_to: "MWB Bazaar",
      amount: Number(plandata.amount),
      // payment_date: moment().format("YYYY-MM-DD"),
      plan_name: Number(plandata?.id),
      wholesaler_id: getWholeSellerId(),
      wholeseller: getWholeSellerId(),
      payment_for: 1,
    };
    // const payment = Number(values?.payment_choice)
    let responseJson1 = await AppService.CreatePayment(body);

    let formdata = {
      wholeseller_plan: [Number(plandata?.id)],
      applyFromNow: applyFromNow,
    };
    const responseJson = await AppService.updateWholeseller(
      getWholeSellerId(),
      formdata
    );
    // console.log('responseJson', responseJson)
    if (responseJson.status == 200 || responseJson.status == 201) {
      Alert("Plan Subscribed Successfully");
      navigate("/profile");
    }
  };

  useEffect(() => {
    if (plandata?.amount === 0) {
      subscribeFreePlan();
    }
  }, [plandata]);

  const getWholesellerData = async () => {
    try {
      const responseJson = await AppService.getWholesellerById(
        getWholeSellerId()
      );
      // setdata(res.data)

      setdata({
        ...responseJson.data,
        wholeseller_type: responseJson.data?.wholeseller_type?.id
          ? responseJson.data?.wholeseller_type?.id
          : [],
        wholeseller_agent: responseJson.data?.wholeseller_agent?.id
          ? responseJson.data?.wholeseller_agent?.id
          : [],
        wholeseller_plan: responseJson.data?.wholeseller_plan?.id
          ? responseJson.data?.wholeseller_plan?.id
          : [],
        wholeseller_state: responseJson.data?.wholeseller_state?.id
          ? responseJson.data?.wholeseller_state?.id
          : [],
        wholeseller_district: responseJson.data?.wholeseller_district?.id
          ? responseJson.data?.wholeseller_district?.id
          : [],
        wholeseller_city: responseJson.data?.wholeseller_city?.id
          ? responseJson.data?.wholeseller_city?.id
          : [],
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const SignupSchema = Yup.object().shape({
    payment_choice: Yup.string().required("payment_choice is required"),
    paid_to: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Contact person is required"),
    // amount: Yup.number().typeError("amount no must be in digit").integer().required("amount is required"),
  });

  let initialValues = {
    payment_choice:
      paymentType === "ONLINEPAYMENT" ? "ONLINEPAYMENT" : "CASH" || "",
    paid_to: "",
    amount: "",
    payment_date: moment().format("YYYY-MM-DD"),
    plan_name: planId,
    wholesaler_id: getWholeSellerId(),
  };

  async function convertImageTobS4(imgUrl: string) {
    const imageToBase64 = require("image-to-base64/browser.js");
    let response = await imageToBase64(imgUrl);
    return "data:image/png;base64," + response;
  }

  const handleFormSubmit = async (values: any) => {
    console.log("values", values);
    let body = {
      payment_choice: paymentType,
      paid_to: values.paid_to,
      amount: Number(plandata.amount),
      // payment_date: moment().format("YYYY-MM-DD"),
      plan_name: Number(plandata?.id),
      wholesaler_id: getWholeSellerId(),
      wholeseller: getWholeSellerId(),
      payment_for: 1,
    };
    const payment = Number(values?.payment_choice);
    console.log("fdhfgjgfhi", typeof payment);
    let formdata = {
      wholeseller_plan: [Number(plandata?.id)],
      plan_apply_from_now: applyFromNow,
    };

    try {
      let responseJson;
      responseJson = await AppService.CreatePayment(body);
      const responseJson1 = await AppService.updateWholeseller(
        getWholeSellerId(),
        formdata
      );
      console.log("responseJson", responseJson);
      if (responseJson.status == 201) {
        Alert("Waiting for Cash Payment Verification");
        navigate("/profile");
      }
    } catch (error: any) {
      console.log("err", error);
    }
  };

  const generatePaymentLink = async () => {
    // console.log("values", values);
    let body = {
      payment_choice: paymentType,
      customer_name: emaildata,
      customer_contact: phonenumberdata,
      customer_email: emaildata,
      amount: Number(plandata.amount) * 100,
      // payment_date: moment().format("YYYY-MM-DD"),
      plan_id: Number(plandata?.id),
      wholesaler_id: getWholeSellerId(),
      wholeseller: getWholeSellerId(),
      payment_for: 1,
    };

    let formdata = {
      wholeseller_plan: [Number(plandata?.id)],
      plan_apply_from_now: applyFromNow,
    };

    try {
      let responseJson;
      responseJson = await AppService.OnlinePayment(body);
      const responseJson1 = await AppService.updateWholeseller(
        getWholeSellerId(),
        formdata
      );
      console.log("responseJson", responseJson);
      Alert("Payment link sent Successfully");
      navigate("/profile");
      // if (responseJson.status == 201) {
      // }
    } catch (error: any) {
      console.log("err", error);
    }
  };

  const inputemailvalue = async (e: any) => {
    let { value } = e.target;
    let regExp = /^[A-Za-z][\w$.]+@[\w]+\.\w+$/;
    if (regExp.test(value)) {
      setshowerroremail(false);
      setEmailData(value);
    } else {
      console.log(regExp.test(value));
      setshowerroremail(true);
      setEmailData("");
    }
  };

  const inputphonenumbervalue = async (e: any) => {
    let { value } = e.target;
    if (value.length == "10") {
      setshowerrorphonenumber(false);
      setphonenumberData(value);
    } else {
      setshowerrorphonenumber(true);
      setphonenumberData("");
    }
  };

  const handelamountdatas = (e: any) => {
    const { value } = e.target;
    if (value.includes("-")) {
      setshowerroramount(true);
      setamountData("");
    } else {
      setshowerroramount(false);
      setamountData(value);
    }
  };

  return (
    <DashboardLayout>
      <div className={classes.root}>
        <div className="container">
          <div className="textContainer">
            <p className="titleHead">
              {plandata?.plan_name} ₹{plandata?.amount || 0}
            </p>
          </div>
          <div className="radio-actionButton">
            <div className="radio-button">
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  name="controlled-radio-buttons"
                  value={paymentType}
                  onChange={() => setPaymentType("CASH")}
                >
                  <FormControlLabel
                    value="Cash "
                    control={<Radio />}
                    checked={paymentType === "CASH" ? true : false}
                    label="Cash "
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="radio-button">
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  name="controlled-radio-buttons"
                  value={paymentType}
                  onChange={() => setPaymentType("ONLINEPAYMENT")}
                >
                  <FormControlLabel
                    value="Online"
                    control={<Radio />}
                    checked={paymentType === "ONLINEPAYMENT" ? true : false}
                    label="Online Payment"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          {paymentType === "CASH" ? (
            <>
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={SignupSchema}
                enableReinitialize={true}
                className="dgdfh"
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <div className="inputField">
                      <input
                        type="date"
                        id="first_name"
                        className="bg-gray-50 border h-[48px] border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-[335px] p-[15px] dark:border-[#EBEAED]"
                        placeholder="Date"
                        name="payment_date"
                        disabled
                        value={values.payment_date}
                        onChange={handleChange}
                      />
                      <Box sx={{ color: "red" }}>
                        <ErrorMessage name="payment_date" />
                      </Box>
                      <div>
                        <input
                          type="number"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          id="first_name"
                          className="bg-gray-50 h-[48px] border border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-[335px] p-[15px] dark:border-[#EBEAED]"
                          placeholder="Amount"
                          name="amount"
                          value={plandata?.amount}
                          disabled
                          // onChange={handleChange}
                          // onInput={handelamountdatas}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="amount" />
                        </Box>
                        {showerroramount && (
                          <span className="text-[red] text-[13px]">
                            enter a positive amount
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border h-[48px] border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-[335px] p-[15px] dark:border-[#EBEAED]"
                        placeholder="Paid To"
                        name="paid_to"
                        value={values.paid_to}
                        onChange={handleChange}
                      />
                      <Box sx={{ color: "red" }}>
                        <ErrorMessage name="paid_to" />
                      </Box>
                    </div>

                    <div
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={applyFromNow}
                        onChange={(e) => setApplyFromNow(e.target.checked)}
                        className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                        name="whatsapp_notification"
                        id="whatsapp_notification"
                      />
                      <label
                        htmlFor="whatsapp_notification"
                        className="fieldTitle !text-[16px]"
                      >
                        Apply from now
                      </label>
                    </div>

                    <div className="actionButton mt-5 flex flex-row items-center">
                      <ActionButton variant="default" title="cancel" />
                      <span className=" w-5 h-1"></span>
                      <ActionButton
                        type="submit"
                        variant="primary"
                        title="save"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <div className="inputField">
                <div>
                  <input
                    type="number"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    id="first_name"
                    className="bg-gray-50 h-[48px] border border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-[335px] p-[15px] dark:border-[#EBEAED]"
                    placeholder="Phone Number"
                    onChange={inputphonenumbervalue}
                  />
                  {showerrorphonenumber && (
                    <span className="text-[red] text-[13px]">
                      Enter valid phone number
                    </span>
                  )}
                </div>
                <div>
                  <input
                    type="email"
                    id="first_name"
                    className="bg-gray-50 border h-[48px] border-gray-300 text-sm rounded-lg text-[#2E2C34] block w-[335px] p-[15px] dark:border-[#EBEAED]"
                    placeholder="Email Address"
                    onChange={inputemailvalue}
                  />
                  {showerroremail && (
                    <span className="text-[red] text-[13px]">
                      Enter valid email address
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  paddingRight: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={applyFromNow}
                  onChange={(e) => setApplyFromNow(e.target.checked)}
                  className="accent-[#4E2FA9] mx-1 !w-[17px] !h-[14px]"
                  name="whatsapp_notification"
                  id="whatsapp_notification"
                />
                <label
                  htmlFor="whatsapp_notification"
                  className="fieldTitle !text-[16px]"
                >
                  Apply from now
                </label>
              </div>

              <div className="paymentButton">
                <ActionButton
                  variant="primary"
                  title="Send Payment Link"
                  onClick={generatePaymentLink}
                />
                <p>
                  <span
                    onClick={() => setAddModalOpen(true)}
                    className="orange"
                  >
                    {" "}
                    Click here
                  </span>
                  <span> if you have received the payment manually</span>
                </p>
              </div>

              <Dialog
                open={addModalOpen}
                maxWidth={"lg"}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "20px",
                  },
                }}
                onClose={() => setAddModalOpen(false)}
              >
                <div className={classes.addDialog}>
                  <p className="title">Add Payment Details</p>
                  <div>
                    <div className={"uploader"}>
                      <div className={"icon"}>
                        <img src={UploaderFrame} alt={"Uploader"} />
                      </div>
                      <div className={"content"}>
                        <div className={"title"}>Upload Screenshot</div>
                        <div className={"subtitle"}>
                          Image can be size of 512 PX by 512 PX Only
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Date"
                      variant="standard"
                      id="fullWidth"
                      className="w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Mode of payment"
                      variant="standard"
                      id="fullWidth"
                      className="w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Transaction ID"
                      variant="standard"
                      id="fullWidth"
                      className="w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Add comment..."
                      variant="standard"
                      id="fullWidth"
                      className="w-full"
                    />
                  </div>

                  <div>
                    <div className={"action-bar"}>
                      <ActionButton
                        variant={"default"}
                        title={"Cancel"}
                        onClick={() => setAddModalOpen(false)}
                      />

                      <ActionButton
                        variant={"primary"}
                        title={"Save"}
                        onClick={() => setAddModalOpen(false)}
                      />
                    </div>
                  </div>
                </div>
              </Dialog>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
