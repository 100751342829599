import React, { useEffect, useState } from "react";
// import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
import { GridOptionButton } from "@/components/atoms/Button";
import complete from "@/static/icons/complete.svg";
import fill from "@/static/icons/fill.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import calendar from "@/static/icons/calendar.svg";
import { AppService } from "@/service/AllApiData.service";
import moment from "moment";
import contact from "@/static/icons/contact-phone.svg";
import { useNavigate } from "react-router-dom";
import { Alert } from "@/alert/Alert";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { useAgentDetailsStyle } from "@/static/stylesheets/screens";
// import { useAgentDetailsStyle } from "@/static/stylesheets/molecules/agentDetailsStyle";
import phone from "@/static/icons/phone.svg";

interface WholesalerRetailerType {
  data?: any;
  id?: any;
}
const WholesalerRetailer: React.FC<WholesalerRetailerType> = ({ data }) => {
  const classes = useAgentDetailsStyle();
  const navigate = useNavigate();
  const [AllWholesaler, setAllWholesaler] = useState<any>();
  const [AllWholesalerM, setAllWholesalerM] = useState<any>();
  const [filterAllwholesellers, setfilterAllwholesellers] = useState<any>();
  const [allDis, setAllDis] = useState<any>([]);
  const [AllState, setAllState] = useState<any>([]);
  const [AllCity, setAllCity] = useState<any>([]);
  const [AllBazaarData, setAllBazaarData] = useState<any>([]);
  const [bazaarList, setBazaarList] = useState([]);
  const [stateList, setstateList] = useState<any[]>([]);
  const [disList, setdisList] = useState<any[]>([]);
  const [cityList, setcityList] = useState<any[]>([]);
  const [AllAgentType, setAllAgentType] = useState<any>([]);
  const [agentTypeList, setagentTypeList] = useState<any>([]);
  const [AllActiveInactive, setAllActiveInactive] = useState<any>([]);
  const [activeInactiveList, setactiveInactiveList] = useState<any>([]);
  const [Allstatus, setAllstatus] = useState<any>([]);
  const [statusList, setstatusList] = useState<any>([]);
  const [wholesellerTypeList, setWholeSellerTyeList] = useState<any[]>([]);
  const [planStatusList, setPlanStatusList] = useState<any[]>([]);

  const wholeseller = data?.wholeseller_firm_name;
  useEffect(() => {
    getAllLists();
  }, [data]);

  console.log(data);

  const getWholeSellerTypeList = async () => {
    try {
      const response = await AppService.getRetailerType({});
      const tempState = response.data.results.map((item: any) => ({
        label: item.retailer_type_name,
        value: item.id,
      }));
      setWholeSellerTyeList(tempState);
    } catch (err: any) {
      console.log("GET RETAILER TYPE ERROR", err?.message);
    }
  };

  const getAllLists = async () => {
    if (data?.id) {
      const responseJson = await AppService.getWholesellerRetailers(data?.id);
      if (responseJson.data?.length > 0) {
        let AllWholeSalerlist = responseJson.data;
        console.log("");

        const filteredWholesalers = AllWholeSalerlist;
        console.log(
          "somethimngrasndong getAllLists",
          filteredWholesalers,
          data
        );
        setAllWholesaler(filteredWholesalers);
        setfilterAllwholesellers(filteredWholesalers);
        setAllWholesalerM(filteredWholesalers);
      }
      console.log(AllWholesaler, "AllWholesaler");
    }
  };

  const handleDeleteFirm = async (index: number, id: any) => {
    if (window.confirm("Do You want to delete Wholeseller")) {
      let deleteuserid = AllWholesaler[index].id;
      const responseJson = await AppService.deleteWholeseller(id);
      if (responseJson.status == 204) {
        Alert("Wholeseller Delete Successfully");
        getAllLists();
      }
    }
  };

  useEffect(() => {
    getAllAgentTypes();
    getAllAgentTypeData();
    getWholeSellerTypeList();
  }, []);
  useEffect(() => {
    filterAllwholesellers?.map((item: any) => {
      getAllDis({ ids: item?.district }, "");
      getAllState({ ids: item?.state }, "");
      getAllCity({ ids: item?.city }, "");
      // getAllBazaarData({ ids: item?.bazaar }, "")
    });
    getAllBazaar();
    getAllStateData();
    // getAllDisData()
    // getAllCityData()
    getAllAgentTypes();
    getAllAgentTypeData();
    getAllActiveInactive();
    getAllActiveInactiveData();
    getAllStatus();
    // getAllStatusData()
  }, [filterAllwholesellers]);

  const getAllDis = async (param: any, type: any) => {
    const responseJson = await AppService.getTotalDistrict(param);
    setAllDis(responseJson.data);
  };
  const getAllState = async (param: any, type: any) => {
    const responseJson = await AppService.getTotalCity(param);
    setAllState(responseJson.data);
  };
  const getAllCity = async (param: any, type: any) => {
    const responseJson = await AppService.getAllCity(param);
    setAllCity(responseJson.data.results);
  };
  const getAllBazaarData = async (param: any, type: any) => {
    const responseJson = await AppService.getAllBazaar(param);
    setAllBazaarData(responseJson.data.results);
  };
  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaar();
    let tempBazaar = await responseJson.data.results.map((row: any) => {
      return {
        label: row.bazaar_name,
        value: row.id,
      };
    });
    setBazaarList(tempBazaar);
  };
  const getAllStateData = async () => {
    const responseJson = await AppService.getTotalCity();
    let tempState = await responseJson.data.map((row: any) => {
      return {
        label: row.state,
        value: row.id,
      };
    });
    setstateList(tempState);
  };
  const getAllDisData = async (params: any) => {
    setdisList([]);
    setcityList([]);

    if (Object.keys(params).length) {
      const responseJson = await AppService.getAllDistricByState(params);
      const tempState = responseJson.data.results.flatMap((row: any) =>
        row.district.map((item: any) => {
          return {
            label: item.district,
            value: item.id,
          };
        })
      );

      console.log("logger123...", allDis, responseJson.data, tempState);
      setdisList(tempState);
    }
  };
  const getAllCityData = async (params: any) => {
    if (Object.keys(params).length) {
      const responseJson = await AppService.getAllCityByDis(params);
      const tempState = responseJson.data.results.flatMap((row: any) =>
        row.city.map((item: any) => {
          return {
            label: item.city,
            value: item.id,
          };
        })
      );
      setcityList(tempState);
    }
  };

  const getAllAgentTypes = async () => {
    const arr = AllWholesaler?.map((item: any) => item?.wholeseller_type_name);
    var uniqueArray = Array.from(new Set(arr));
    setAllAgentType(uniqueArray);
  };
  const getAllAgentTypeData = async () => {
    if (AllAgentType.length > 0) {
      let tempState = AllAgentType.map((row: any) => {
        return {
          label: row,
          value: row,
        };
      });
      setagentTypeList(tempState);
    }
  };
  const getAllActiveInactive = async () => {
    const arr = AllWholesaler?.map((item: any) => {
      if (item.wholeseller_active === true) {
        return true;
      } else {
        return false;
      }
    });
    var uniqueArray = Array.from(new Set(arr));
    setAllActiveInactive(uniqueArray);
  };
  const getAllActiveInactiveData = async () => {
    if (AllActiveInactive.length > 0) {
      let tempState = AllActiveInactive.map((row: any) => {
        return {
          label: row === true ? "Enable" : "Disable",
          value: row === true ? true : false,
        };
      });
      setactiveInactiveList(tempState);
    }
  };
  const getAllStatus = async () => {
    const arr = AllWholesaler?.map((item: any) => item.retailer_status);
    const arr2 = AllWholesaler?.map((item: any) => item.retailer_plan);

    var uniqueArray = Array.from(new Set(arr));
    var uniqueArray2 = Array.from(new Set(arr2));

    getAllStatusData(uniqueArray);
    getAllPlanStatus(uniqueArray2);
  };
  const getAllStatusData = async (uniqueArray: any[]) => {
    if (uniqueArray.length > 0) {
      let tempState = uniqueArray.map((row: any) => {
        return {
          label: row,
          value: row,
        };
      });
      setstatusList(tempState);
    }
  };

  const getAllPlanStatus = async (uniqueArray: any[]) => {
    if (uniqueArray.length > 0) {
      let tempState = uniqueArray
        .map((row: any) => {
          if (row) {
            return {
              label: row,
              value: row,
            };
          }
        })
        .filter((item: any) => item !== undefined);
      setPlanStatusList(tempState);
    }
  };

  const handleChange = (selectboxName: string, id: any) => {
    if (selectboxName === "State") {
      const updatedData = stateList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      const ids = updatedData
        .filter((item: any) => item.status === true)
        .map((item: any) => item.value)
        .join(",");
      console.log("logger123...", updatedData, id, AllState);
      getAllDisData({ ids });
      setstateList(updatedData);
    }
    if (selectboxName === "District") {
      const updatedData = disList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      const ids = updatedData
        .filter((item: any) => item.status === true)
        .map((item: any) => item.value)
        .join(",");
      console.log("logger123...", updatedData, id, AllState);
      getAllCityData({ ids });
      setdisList(updatedData);
    }
    if (selectboxName === "City") {
      const updatedData = cityList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );

      setcityList(updatedData);
    }
    if (selectboxName === "Retailer Type") {
      const updatedData = wholesellerTypeList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      console.log("somethimngrasndong", wholesellerTypeList, updatedData);

      setWholeSellerTyeList(updatedData);
    }
    if (selectboxName === "Agent Type") {
      const updatedAgentType = agentTypeList?.map((item: any) => ({
        ...item,
        status: item.value === id ? true : false,
      }));
      setagentTypeList(updatedAgentType);
    }
    if (selectboxName === "Status") {
      const updatedStatus = statusList.map((item: any) => ({
        ...item,
        status: item.value === id ? true : false,
      }));
      setstatusList(updatedStatus);
    }
    if (selectboxName === "Plan Status") {
      const updatedData = planStatusList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      setPlanStatusList(updatedData);
    }
  };

  useEffect(() => {
    let temp1 = AllWholesalerM;
    // city
    if (temp1?.length && cityList?.length) {
      let FCity = cityList
        ?.filter((b: any) => b.status === true)
        ?.map((item: any) => item?.id);
      if (FCity?.length > 0) {
        for (const x of FCity) {
          temp1 = temp1.filter((fc: any) => fc.retailer_city === x);
        }
      }
    }
    // state
    if (temp1?.length && stateList?.length) {
      let FState = stateList
        ?.filter((b: any) => b.status === true)
        ?.map((item: any) => item?.id);
      if (FState?.length > 0) {
        for (const x of FState) {
          temp1 = temp1?.filter((fs: any) => fs?.retailer_state === x);
        }
      }
    }
    // district
    if (temp1?.length && disList?.length) {
      let FDistrict = disList
        ?.filter((b: any) => b.status === true)
        ?.map((item: any) => item?.id);
      if (FDistrict?.length > 0) {
        for (const x of FDistrict) {
          temp1 = temp1?.filter((fs: any) => fs?.retailer_district === x);
        }
      }
    }
    // wholeseller type
    if (temp1?.length && wholesellerTypeList?.length) {
      const selectedAgentType = wholesellerTypeList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedAgentType?.length > 0) {
        temp1 = temp1?.filter((item: any) =>
          selectedAgentType?.includes(item?.retailer_type)
        );
      }
    }
    // wholeseller status
    if (temp1?.length && statusList?.length) {
      console.log("somethimngrasndong wholeseller status", temp1, statusList);

      const selectedStatus = statusList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedStatus?.length > 0) {
        temp1 = temp1?.filter((item: any) =>
          selectedStatus?.includes(item?.retailer_status)
        );
      }
    }
    // active / inactive
    if (temp1?.length && planStatusList?.length) {
      const selectedActiveInactive = planStatusList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedActiveInactive?.length > 0) {
        temp1 = temp1?.filter((item: any) =>
          selectedActiveInactive?.includes(item?.retailer_plan)
        );
      }
    }
    setAllWholesaler(temp1);
  }, [
    stateList,
    disList,
    cityList,
    wholesellerTypeList,
    statusList,
    planStatusList,
  ]);

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className="flex gap-5 pt-[30px] " style={{ flexWrap: "wrap" }}>
            <CommonSelectElectronicBazaar
              label={"State"}
              hint={"Select State"}
              options={stateList}
              handleSelect={handleChange}
              selectedIds={stateList
                .filter((item: any) => item?.status)
                .map((elm: any) => elm.value)}
            />
            <CommonSelectElectronicBazaar
              disable={!stateList.filter((item: any) => item?.status).length}
              label={"District"}
              hint={"Select District"}
              options={disList}
              handleSelect={handleChange}
              selectedIds={disList
                .filter((item: any) => item?.status)
                .map((elm: any) => elm.value)}
            />
            <CommonSelectElectronicBazaar
              disable={!disList.filter((item: any) => item?.status).length}
              label={"City"}
              hint={"Select City"}
              options={cityList}
              handleSelect={handleChange}
              selectedIds={cityList
                .filter((item: any) => item?.status)
                .map((elm: any) => elm.value)}
            />
            <CommonSelectElectronicBazaar
              label={"Retailer Type"}
              hint={"Select Retailer Type"}
              options={wholesellerTypeList}
              handleSelect={handleChange}
              selectedIds={wholesellerTypeList
                .filter((item: any) => !!item?.status)
                .map((elm: any) => elm.value)}
            />
            <CommonSelectElectronicBazaar
              label={"Status"}
              hint={"Select Status"}
              options={statusList}
              handleSelect={handleChange}
              selectedIds={statusList
                ?.filter((item: any) => item?.status)
                .map((elm: any) => elm?.value)}
            />
            {/* <CommonSelectElectronicBazaar
                            label={"Active/Inactive"}
                            hint={"Select Active/Inactive"}
                            options={activeInactiveList}
                            handleSelect={handleChange}
                            selectedIds={activeInactiveList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                        <CommonSelectElectronicBazaar
                            label={"Agent Type"}
                            hint={"Select Agent Type"}
                            options={agentTypeList}
                            handleSelect={handleChange}
                            selectedIds={agentTypeList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        /> */}
            <CommonSelectElectronicBazaar
              label={"Plan Status"}
              hint={"Select Plan Status"}
              options={planStatusList}
              handleSelect={handleChange}
              selectedIds={planStatusList
                ?.filter((item: any) => item?.status)
                .map((elm: any) => elm?.value)}
            />
          </div>

          <div>
            <div
              className="relative pt-[40px] pb-[40px]"
              style={{ overflowX: "auto" }}
            >
              <table
                className="w-full text-left "
                style={{ marginBottom: "190px" }}
              >
                <thead
                  className=""
                  style={{ borderBottom: "1px solid #e1e1e1" }}
                >
                  <tr className="color-[#2E2C34;]">
                    <th
                      scope="col"
                      className="tableTitle py-3 px-6"
                      style={{ paddingLeft: 0 }}
                    >
                      Name
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      Contact Person
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      City
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      Bazaar
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      Type
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      Plan
                    </th>
                    <th scope="col" className="tableTitle py-3 px-6">
                      # of Orders
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {AllWholesaler?.length > 0 ? (
                    AllWholesaler?.map((elm: any, index: any) => {
                      // const retailertype = Allretailer.find((it: any) => it.id === elm?.wholeseller_retailer_type)
                      // console.log('retailertype', retailertype)
                      return (
                        <tr className="border-b" key={elm.id}>
                          <td
                            scope="row"
                            className="py-4 px-6 tableData"
                            style={{ paddingLeft: 0 }}
                          >
                            <p className="tableData">{elm?.retailer_name}</p>
                          </td>
                          <td className="py-4 px-6 tableData person-details">
                            <div className="flex items-center gap-2 text-[#4E2FA9]">
                              <img
                                className="brandLogo"
                                src={contact}
                                alt={"Logo"}
                              />
                              <span style={{ textDecoration: "underline" }}>
                                {elm?.retailer_contact_per}
                              </span>
                            </div>
                            <div className="flex flex-col items-start gap-2 person-model">
                              <span className="job-title">Firm Manager</span>
                              <span className="text-sm text-[#2E2C34] font-medium">
                                {elm.retailer_contact_per}
                              </span>
                              <span className="text-[13px] text-[#84818A] font-medium leading-5">
                                {AllCity.map((item: any) => {
                                  if (item.id === elm?.retailer_city) {
                                    return item?.city;
                                  }
                                })}
                              </span>
                              <span className="flex items-center gap-2 text-sm text-[#2E2C34] font-medium">
                                <img src={phone} alt="phone" />{" "}
                                {elm.retailer_number_and_details
                                  .map((item: any) => item?.retailer_number)
                                  .join(", ")}
                              </span>
                            </div>
                          </td>
                          <td className="py-4 px-6 tableData person-details">
                            <div className="flex items-center gap-2">
                              {AllCity.map((item: any) => {
                                if (item.id === elm?.retailer_city) {
                                  return item?.city;
                                }
                              })}
                            </div>
                          </td>
                          <td className="py-4 px-6 tableData">
                            {elm?.wholeseller_details?.flatMap((item: any) =>
                              item.wholeseller_bazaar_data.map(
                                (bazaar: any) => (
                                  <span className="group relative" key={index}>
                                    <button
                                      className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm"
                                      style={{
                                        fontSize: "12px",
                                        marginRight: "15px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {bazaar?.bazaar_name}
                                    </button>
                                  </span>
                                )
                              )
                            )}
                          </td>
                          <td className="py-4 px-6 tableData">
                            {elm?.retailer_type_name?.[0]?.retailer_type_name}
                          </td>
                          <td className="py-4 px-6 tableData">
                            {elm?.retailer_plan}
                          </td>
                          <td className="py-4 px-6 tableData cursor-pointer font-[500] text-[#2E2C34] text-[14px] font-[Manrope]">
                            {elm?.retailer_no_of_bills_allowed}
                          </td>
                          <td
                            className="py-4 px-6 tableData cursor-pointer"
                            style={{ paddingRight: 0 }}
                          >
                            <GridOptionButton
                              icon={"vertical-options"}
                              menus={[
                                {
                                  label: (
                                    <>
                                      <span className="icon">
                                        <img src={complete} alt="dots" />
                                      </span>{" "}
                                      Complete KYC
                                    </>
                                  ),
                                  onClick() {
                                    navigate(`/wholesellerkyc/${elm.id}/KYC`);
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <span className="icon">
                                        <img src={fill} alt="fill" />{" "}
                                      </span>{" "}
                                      Edit Firm
                                    </>
                                  ),
                                  onClick() {
                                    navigate(`/wholesellerkyc/${elm.id}/EDIT`);
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <span className="icon">
                                        <img
                                          src={deleteagent}
                                          alt="deleteagent"
                                        />{" "}
                                      </span>
                                      Delete Firm
                                    </>
                                  ),
                                  onClick() {
                                    handleDeleteFirm(index, elm.id);
                                  },
                                },
                                {
                                  label: (
                                    <>
                                      <span className="icon">
                                        <img src={calendar} alt="calendar" />{" "}
                                      </span>{" "}
                                      Renew Plan
                                    </>
                                  ),
                                  onClick() {
                                    navigate(`/renewplan/${elm.id}`);
                                  },
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p
                      className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]"
                      style={{ paddingTop: "10px" }}
                    >
                      No Wholesaler retailers yet
                    </p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WholesalerRetailer;
