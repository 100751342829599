import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { AddButton, GridOptionButton } from "@/components/atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useRetailerStyles } from "@/static/stylesheets/screens/retailersStyle";
import { Grid, Pagination } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import fill from "@/static/icons/fill.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { Alert, AlertError } from "@/alert/Alert";
import Loading from "@/components/LoadingCom/Loading";
import { Switch } from "@/components/atoms/Switch";
import IcCheckMark from "@/static/svg/ic_checkmark.svg";
import { ActionButton } from "@/components/atoms/Button/ActionButton";

const SelectAddonPlans = () => {
  const classes = useRetailerStyles();
  const navigate = useNavigate();
  const { planId, id } = useParams();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [addonPlans, setAddonPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, SetTotalCount] = useState(0);
  const [selected, setSelected] = useState();

  const getAddonPlans = async () => {
    try {
      let params: any = {
        page: currentPage,
        plan: planId,
      };

      if (search) {
        params = {
          ...params,
          search: search,
        };
      }

      const response = await AppService.getAddonPlans(params);
      setAddonPlans(response.data.results);
      SetTotalCount(response.data.count);
      setLoading(false);
    } catch (err: any) {
      console.log("ERROR ====> ", err);
    }
  };

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const buyPlan = async () => {
    if (selected) {
      navigate(`/addons/${planId}/pay/${selected}`);
    } else {
      AlertError("Please select a plan before proceeding");
    }
    // try{
    //     let body = {
    //         payment_choice:"CASH",
    //         paid_to:"Neel",
    //         amount:0,
    //         plan_name:1,
    //         wholesaler_id:"113",
    //         wholeseller:"113"
    //      }

    //     //  const response = await AppService.
    // } catch (error) {
    //     console.log("ERROR ====> ",error)
    // }
  };

  useEffect(() => {
    getAddonPlans();
  }, [search, currentPage]);

  return (
    <>
      <DashboardLayout>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={classes.root}>
              <div className="mb-[32px]">
                <div
                  className="flex align-middle justify-between"
                  style={{ alignItems: "center" }}
                >
                  <p className="commonTitle">Select Addon Plan</p>
                  <div className="flex gap-5 align-middle justify-end">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search ..."
                        className="w-[576px] py-[11px] px-4 h-[48px] text-gray-500 border rounded-[4px] outline-none bg-gray-50 focus:bg-white"
                        // onKeyDown={(e: any) => e.key === 'Enter' && getPlans()}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 right-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>
                    <ActionButton
                      disable={addonPlans.length === 0}
                      variant="primary"
                      title="Buy Now"
                      onClick={() => buyPlan()}
                    />
                  </div>
                </div>
              </div>

              <div>
                {addonPlans.length ? (
                  <div
                    className="review-plans grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 px-2"
                    style={{ marginRight: 0, paddingBottom: "30px" }}
                  >
                    {addonPlans.map((item: any, index: number) => (
                      // <Grid item lg={4} md={4} sm={6} style={{ paddingTop: 0 }} >
                      <div
                        className="h-full "
                        onClick={() => setSelected(item.id)}
                      >
                        <div
                          className={`border border-[#4E2FA9] rounded-xl p-[30px] h-full relative`}
                        >
                          {selected === item?.id && (
                            <img
                              className="w-[22px] absolute top-[15px] right-[15px]"
                              src={IcCheckMark}
                              alt={"Logo"}
                            />
                          )}

                          <div
                            className="flex justify-between"
                            style={{ alignItems: "center" }}
                          >
                            <div>
                              <p className="text-[#4E2FA9] text-[22px] font-bold">
                                {item?.plan_name}
                              </p>
                            </div>
                            <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                              <p className="text-[#FF6652] text-[28px] font-bold leading-none">
                                <p className="text-[#FF6652] text-[28px]">
                                  ₹{item?.amount}
                                </p>
                                {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {localStoragedata.plan_periods_in_days}
                      </p> */}
                                {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                                            {localStoragedata.plan_periods_in_days} Days
                                          </p> */}
                                {/* </> */}
                              </p>
                            </div>
                          </div>

                          <div className="mt-[40px]">
                            <p className="font-[#121127] text-[24px] font-bold mb-2">
                              Features
                            </p>
                            <div
                              className="flex gap-5 pb-6 all-features-div"
                              style={{ overflowY: "auto" }}
                            >
                              <ul>
                                {/* {previewData?.featureData?.length > 0 ? (
                        previewData?.featureData.map((it: any) => ( */}
                                {item?.plan_tally && (
                                  <>
                                    <li className="mb-[5px]">
                                      <div className="flex gap-4 items-center">
                                        <img
                                          className="w-[15px]"
                                          src={IcCheckMark}
                                          alt={"Logo"}
                                        />
                                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                          Tally Support
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                )}
                                {item?.whatsapp_notification_limit && (
                                  <>
                                    <li className="mb-[5px]">
                                      <div className="flex gap-4 items-center">
                                        <img
                                          className="w-[15px]"
                                          src={IcCheckMark}
                                          alt={"Logo"}
                                        />
                                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                          {item?.whatsapp_notification_limit}{" "}
                                          Whatsapp Notification
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                )}

                                {item?.sms_notification_limit && (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.sms_notification_limit} SMS
                                        Notification
                                      </p>
                                    </div>
                                  </li>
                                )}

                                {item?.agents_limit && (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.agents_limit} Agents
                                      </p>
                                    </div>
                                  </li>
                                )}

                                {item?.products_limit && (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.products_limit} Products
                                      </p>
                                    </div>
                                  </li>
                                )}

                                {item?.orders_limit ? (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.orders_limit} Order
                                      </p>
                                    </div>
                                  </li>
                                ) : (
                                  ""
                                )}

                                {item?.branches ? (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.branches} Branches
                                      </p>
                                    </div>
                                  </li>
                                ) : (
                                  ""
                                )}

                                {item?.retailers_limit && (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.retailers_limit} Retailers
                                      </p>
                                    </div>
                                  </li>
                                )}

                                {item?.users_limit && (
                                  <li className="mb-[5px]">
                                    <div className="flex gap-4 items-center">
                                      <img
                                        className="w-[15px]"
                                        src={IcCheckMark}
                                        alt={"Logo"}
                                      />
                                      <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                                        {item?.users_limit} Users
                                      </p>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      // </Grid>
                    ))}
                  </div>
                ) : (
                  <div className="w-full min-h-[10rem] flex items-center justify-center">
                    <h4 className="font-medium text-xl">
                      No addons available for your current plan
                    </h4>
                  </div>
                )}
              </div>

              <div
                className="flex items-center justify-between"
                style={{ display: "flex", marginLeft: 0 }}
              >
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </>
        )}
      </DashboardLayout>
    </>
  );
};
export default SelectAddonPlans;
