import React from "react";
import { SidebarPanel } from "@/components/organisms/SidebarPanel";
import { MenuItem } from "@/components/organisms/SidebarPanel/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SettingsIcon from "@mui/icons-material/Settings";
import { routePaths } from "@/routes";
import PersonIcon from "@mui/icons-material/Person";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ViewListIcon from "@mui/icons-material/ViewList";
import { BsBank } from "react-icons/bs";

const AdminSidebar = () => {
  return (
    <>
      <SidebarPanel>
        <MenuItem
          href={routePaths.dashboard}
          icon={<DashboardIcon />}
          label={"Dashboard"}
          name="dashboard"
        />
        <MenuItem
          href={routePaths.branch}
          icon={<PersonIcon />}
          label={"Branch"}
          name="branch"
        />
        <MenuItem
          // href={routePaths.wholesellerproducts}
          icon={<ShoppingCartIcon />}
          label={"Products"}
          name="wholesellerproducts"
          subMenu={[
            {
              label: "All Products",
              href: routePaths.wholesellerproducts,
              name: "wholesellerproducts",
            },
            {
              label: "Product Combo",
              href: routePaths.productcombos,
              name: "productcombos",
            },
            {
              label: "Product Stock",
              href: routePaths.productstock,
              name: "productstock",
            },
            {
              label: "Product Pricing",
              href: routePaths.productpricing,
              name: "productpricing",
            },
          ]}
        />
        {/* <MenuItem
          href={routePaths.productcombos}
          icon={<ShoppingCartIcon />}
          label={"Product Combo"}
          name="productcombos"
        /> */}
        {/* <MenuItem
          href={routePaths.productstock}
          icon={<ShoppingCartIcon />}
          label={"Product Stock"}
          name="productstock"
        /> */}
        {/* <MenuItem
          href={routePaths.productpricing}
          icon={<ShoppingCartIcon />}
          label={"Product Pricing"}
          name="productpricing"
        /> */}
        <MenuItem
          href={"/order"}
          icon={<ViewListIcon />}
          label={"Order"}
          name="order"
        />
      
        <MenuItem
          href={"/agents"}
          icon={<PersonIcon />}
          label={"Agents"}
          name="agents"

        />
        <MenuItem
          href={"/retailers"}
          icon={<PersonIcon/>}
          label={"Retailers"}
          name="retailers"
        />
        <MenuItem
          href={"/offers"}
          icon={<LocalOfferIcon />}
          label={"Offers"}
          name="offers"
        />
        <MenuItem
          href={"/branchplan"}
          icon={<CardMembershipIcon />}
          label={"Plans"}
          name="branchplan"
          subMenu={[
            {
              label: "Item Plans",
              href: "/itemwiseplan",
              name: "itemwiseplan",
            },
            {
              label: "View Product Pricing",
              href: "/view-product-pricings",
              name: "view-product-pricings",
            },
          ]}
        />
        {/* <MenuItem
          href={"/itemwiseplan"}
          icon={<CardMembershipIcon />}
          label={"Item Plans"}
          name="itemwiseplan"
        /> */}

        {/* <MenuItem
          href={"/view-product-pricings"}
          icon={<CardMembershipIcon />}
          label={"View Product Pricing"}
          name="view-product-pricings"
        /> */}

        <MenuItem
          href={"/customer-wise-pricings"}
          icon={<PersonIcon />}
          label={"Customer Wise Pricing"}
          name="customer-wise-pricings"
        />

        <MenuItem
          icon={<BsBank />}
          label={"My Account"}
          name="bank-details"
          subMenu={[
            {
              label: "Profile",
              href: "/profile?content=profile",
              name: "profile",
            },
            {
              label: "My Plan",
              href: "/profile?content=my-plan",
              name: "my-plan",
            },
            {
              label: "Reports",
              href: "/profile?content=reports",
              name: "reports",
            },
            {
              label: "Bank Details",
              href: "/bank-details",
              name: "bank-details",
            },
          ]}
        />

        <MenuItem
          icon={<SettingsIcon />}
          label={"Settings"}
          name="mwb"
          subMenu={[
            {
              label: "Routes",
              href: "/mwb",
              name: "mwb",
            },
            {
              label: "Roles & Permissions",
              href: "/users",
              name: "users",
            },
          ]}
        />
        {/* <MenuItem
          href={"/users"}
          icon={<SettingsIcon />}
          label={"Roles & Permissions"}
          name="users"
        /> */}
        {/* <MenuItem
          href={"/trackorder"}
          icon={<SettingsIcon />}
          label={"Activity Tracking"}
        />
        <MenuItem
          href={routePaths.admin.masterList}
          icon={<SettingsIcon />}
          label={"Master"}
        /> */}
      </SidebarPanel>
    </>
  );
};

export { AdminSidebar };
