import axios from "axios";
import { Navigate } from "react-router-dom";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Run the function if status is 401
      // handleUnauthorized();
      console.log("refresh page");
    }
    // Return the error so it can be handled by the calling code if needed
    return Promise.reject(error);
  }
);

export default axios.create({
  baseURL: "https://api.mwbbazaar.com/api",
  headers: {
    // "Content-type": "multipart/form-data",
  },
});
