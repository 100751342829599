import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useaddBranchStyles } from "@/static/stylesheets/screens";
import ArrowIcon from "@/static/svg/ic_arrow.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "@/Store";
import { CategorySlice } from "@/Store/Sclicese/CategorySlice";
import { AppDispatch } from "@/Store";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { SubCategorySlice } from "@/Store/Sclicese/SubCategorySlice";
import * as Yup from "yup";
import {
  GetCityList,
  GetCityListFromDistrict,
} from "@/Store/Sclicese/CityListSlice";
import { GetstateList } from "@/Store/Sclicese/StateListSlice";
import {
  GetdistrictList,
  GetdistrictListFromState,
} from "@/Store/Sclicese/DistrictListSlice";
import { GetWholeseller } from "@/Store/Sclicese/WholesellerSlice";
import {
  Createbranch,
  Editbrach,
  GetoneBranchList,
} from "@/Store/Sclicese/BranchSlice";
import PhoneInput from "react-phone-input-2";
import { object, string } from "prop-types";
import { ListItemDecorator } from "@mui/joy";
import { ErrorMessage, Formik } from "formik";

const AddBranch = () => {
  const classes = useaddBranchStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  const [initialState, setInitialState] = useState<any>({
    branch_name: "",
    manager_name: "",
    branch_phone: "",
    category_name: "",
    subcategory_name: "",
    item_name: "",
    address: "",
    address_line2: "",
    landmark: "",
    pincode_no: "",
    created_at: "",
    city: "",
    state: "",
    district: "",
    branch_wholeseller: null,
  });
  const [categoryId, setCategoryId] = useState();
  const ProductBazaar: any = useSelector(
    (state) => state?.ProductBazaarList?.data?.results
  );
  const parentCategory: any = useSelector(
    (state) => state?.GroupCategoryData?.data?.results
  );
  const CategoryData: any = useSelector(
    (state) => state?.CategoryGroup?.data?.results
  );
  const SubCategoryData: any = useSelector(
    (state) => state?.SubCategoryData?.data?.results
  );
  // const CityListData: any = useSelector(state => state?.CityListData?.data)
  // const DistrictListData: any = useSelector(state => state?.DistrictListdata?.data)
  // const StateListData: any = useSelector(state => state?.StateListData?.data)
  const Wholesellerdata: any = useSelector(
    (state) => state?.Wholesellerdata?.data
  );
  const GetoneBranchListdata: any = useSelector(
    (state) => state?.GetoneBranchListReducers.data
  );
  const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "");
  const validationSchema = Yup.object().shape({
    branch_name: Yup.string().required("This field is required"),
    manager_name: Yup.string().required("This field is required"),
    branch_phone: Yup.number()
      .typeError("Phone no must be in digit")
      .integer()
      .positive("Phone no must be positive")
      .required("Phone no is required"),
    address: Yup.string().required("This field is required"),
    landmark: Yup.string().required("This field is required"),
    pincode_no: Yup.string()
      .min(6, "Too Short!")
      .required("Pincode is required"),
    // city: Yup.string().required("This field is required"),
    // state: Yup.string().required("This field is required"),
    // district: Yup.string().required("This field is required"),
  });
  useEffect(() => {
    console.log(Wholesellerdata, "-------");
  }, [Wholesellerdata]);
  useEffect(() => {
    dispatch(ProductBazaarList());
    dispatch(GetstateList());
    dispatch(GetWholeseller());
    setInitialState({
      ...initialState,
      branch_wholeseller: Number(localStorage.getItem("Wholeseller_id") || 0),
    });
    console.log("wholeseller_ID", localStorage.getItem("Wholeseller_id"));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(GetoneBranchList(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && Object.keys(GetoneBranchListdata).length > 0) {
      setInitialState({
        ...GetoneBranchListdata,
        state: GetoneBranchListdata.state.id,
        district: GetoneBranchListdata.district.id,
        city: GetoneBranchListdata.city.id,
      });
    }
  }, [GetoneBranchListdata]);

  console.log("Createbranchdata", initialState);
  useEffect(() => {
    if (ProductBazaar) {
      if (parentCategory) {
        dispatch(CategorySlice({ bazaarId: bazaarID }));
        if (categoryId) {
          dispatch(SubCategorySlice({ bazaarId: bazaarID }));
        }
      }
    }
  }, [
    ProductBazaar?.length,
    CategoryData?.length,
    parentCategory?.length,
    categoryId,
  ]);

  const handleChangeMasterType = (e: any) => {
    const { name, value } = e.target;
    if (name == "state") {
      dispatch(GetdistrictListFromState(value));
    }
    if (name == "district") {
      dispatch(GetCityListFromDistrict(value));
    }
    if (Number(value)) {
      setInitialState((prev: any) => ({ ...prev, [name]: Number(value) }));
    } else {
      initialState.created_at = new Date().toJSON().slice(0, 10);
      setInitialState((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const setBranchPhonefun = (e: any) => {
    initialState.branch_phone = `+${e}`;
  };

  const onSubmit = (values: any) => {
    console.log(values);

    const data = { data: values, nevigate: nevigate };
    if (id) {
      dispatch(Editbrach(data));
    } else {
      dispatch(Createbranch(data));
    }
  };

  return (
    <>
      <DashboardLayout>
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => (
            <div className={`${classes.root} add_branch`}>
              <div>
                <div className="mb-[53px] flex itens-center">
                  <div
                    className="cursor-pointer flex me-5"
                    onClick={() => nevigate("/branch")}
                  >
                    <img src={ArrowIcon} alt={"Logo"} />
                  </div>
                  <p className="commonTitle">
                    {id ? "Edit Branch" : "Add New Branch"}
                  </p>
                </div>

                <div className="formContainer mb-[32px]">
                  <div className="w-1/2">
                    <label className="branchLabel">
                      Branch name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="branch_name"
                      required
                      onChange={handleChangeMasterType}
                      value={initialState.branch_name}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="branch_name" />
                    </div>
                  </div>

                  <div className="w-1/2">
                    <label className="branchLabel">
                      Manager name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="manager_name"
                      onChange={handleChangeMasterType}
                      value={initialState.manager_name}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="manager_name" />
                    </div>
                  </div>
                </div>
                <div className="formContainer mb-[32px]">
                  <div className="grid grid-cols-2 items-end gap-4 w-full">
                    <FormControl variant="standard" style={{ width: "100%" }}>
                      <label className="branchLabel">
                        Branch Phone<span className="text-red-600">*</span>
                      </label>
                      <PhoneInput
                        country={"in"}
                        value={initialState?.branch_phone}
                        placeholder="Enter Phone Number"
                        onChange={(e: any) => setBranchPhonefun(e)}
                        containerStyle={{
                          width: "100%",
                        }}
                      />
                      <div className=" text-xs text-red-600 mt-2">
                        <ErrorMessage name="branch_phone" />
                      </div>
                    </FormControl>
                    {/* <div className="">
                  <label className="branchLabel">Category</label>
                  <Select
                    label="Category"
                    variant={"standard"}
                    fullWidth={true}
                    value={initialState.category_name}
                    name="category_name"
                    onChange={handleChangeMasterType}
                  >
                    {
                      CategoryData && CategoryData?.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.category_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div> */}
                  </div>
                </div>
                {/* <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">Sub Category</label>
                <Select
                  label="Sub Category"
                  variant={"standard"}
                  fullWidth={true}
                  value={initialState.subcategory_name}
                  name="subcategory_name"
                  onChange={handleChangeMasterType}
                >
                  {
                    SubCategoryData?.length > 0 ? SubCategoryData?.map((item: any) => (
                      <MenuItem value={item.id}>
                        {item.subcategory_name}
                      </MenuItem>
                    )) : <p className="text-center py-2 text-[#84818A] text-[14px]">Please select Category</p>
                  }
                </Select>
              </div>

              <div className="w-1/2">
                <label className="branchLabel">Item</label>
                <input type="number" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="item_name" value={initialState.item_name} onChange={handleChangeMasterType} />
              </div>
            </div> */}
                <div className="formContainer mb-[32px]">
                  <div className="w-1/2">
                    <label className="branchLabel">
                      Address Line 1<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="address"
                      value={initialState.address}
                      required
                      onChange={handleChangeMasterType}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="address" />
                    </div>
                  </div>

                  <div className="w-1/2">
                    <label className="branchLabel">Address Line 2</label>
                    <input
                      type="text"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="address_line2"
                      value={initialState.address_line2}
                      onChange={handleChangeMasterType}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="address_line2" />
                    </div>
                  </div>
                </div>
                <div className="formContainer mb-[32px]">
                  <div className="w-1/2">
                    <label className="branchLabel">
                      Pincode<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="pincode_no"
                      value={initialState.pincode_no}
                      required
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={handleChangeMasterType}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="pincode_no" />
                    </div>
                  </div>

                  <div className="w-1/2">
                    <label className="branchLabel">
                      Landmark<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full"
                      name="landmark"
                      value={initialState.landmark}
                      onChange={handleChangeMasterType}
                    />
                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="landmark" />
                    </div>
                  </div>
                </div>
                <div className="formContainer mb-[32px]">
                  <div className="w-1/2">
                    <label className="branchLabel">State</label>

                    <Autocomplete
                      options={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]
                          ?.bazaar_state_names || []
                      }
                      getOptionLabel={(option: any) => option.state || ""}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="State"
                          variant="standard"
                          fullWidth
                          name="state"
                        />
                      )}
                      value={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_state_names.find(
                          (item: any) => item.id === initialState.state
                        ) || null
                      }
                      onChange={(event: any, newValue: any) =>
                        handleChangeMasterType({
                          target: { name: "state", value: newValue.id },
                        })
                      }
                    />

                    {/* <Select
                  label="State"
                  variant={"standard"}
                  fullWidth={true}
                  name="state"
                  value={initialState.state}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_state_names?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.state}
                      </MenuItem>
                    ))
                  }
                </Select> */}
                  </div>
                  <div className="w-1/2">
                    <label className="branchLabel">district</label>

                    <Autocomplete
                      options={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names?.filter(
                          (item: any) => item.state === initialState.state
                        ) || []
                      }
                      getOptionLabel={(option: any) => option.district || ""}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="State"
                          variant="standard"
                          fullWidth
                          name="state"
                        />
                      )}
                      value={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names
                          ?.filter(
                            (item: any) => item.state === initialState.state
                          )
                          .find(
                            (item: any) => item.id === initialState.district
                          ) || null
                      }
                      onChange={(event: any, newValue: any) =>
                        handleChangeMasterType({
                          target: { name: "district", value: newValue.id },
                        })
                      }
                    />

                    {/* <Select
                  label="State"
                  variant={"standard"}
                  fullWidth={true}
                  name="district"
                  value={initialState.district}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names?.filter((item: any) => item.state === initialState.state)?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.district}
                      </MenuItem>
                    ))
                  }
                </Select> */}
                  </div>
                </div>

                <div className="formContainer mb-[32px]">
                  <div className="w-1/2">
                    <label className="branchLabel">City</label>

                    <Autocomplete
                      options={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names?.filter(
                          (item: any) => item.district === initialState.district
                        ) || []
                      }
                      getOptionLabel={(option: any) => option.city || ""}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="State"
                          variant="standard"
                          fullWidth
                          name="state"
                        />
                      )}
                      value={
                        Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names
                          ?.filter(
                            (item: any) =>
                              item.district === initialState.district
                          )
                          .find((item: any) => item.id === initialState.city) ||
                        null
                      }
                      onChange={(event: any, newValue: any) =>
                        handleChangeMasterType({
                          target: { name: "city", value: newValue.id },
                        })
                      }
                    />

                    {/* <Select
                  label="City"
                  variant={"standard"}
                  fullWidth={true}
                  name="city"
                  value={initialState.city}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names?.filter((item: any) => item.district === initialState.district)?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.city}
                      </MenuItem>
                    ))
                  }
                </Select> */}
                  </div>
                  {/* <div className="w-1/2">
                <label className="branchLabel">Branch Wholeseller</label>
                <Select
                  label="City"
                  variant={"standard"}
                  fullWidth={true}
                  value={initialState.branch_wholeseller}
                  name="branch_wholeseller"
                  onChange={handleChangeMasterType}
                >
                  {
                    [Wholesellerdata]?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.wholeseller_firm_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div> */}
                </div>

                <div className="flex gap-5 py-5">
                  <ActionButton
                    title="Submit"
                    variant="primary"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </DashboardLayout>
    </>
  );
};

export default AddBranch;
